import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';

import Tooltip from 'af-components/Tooltip';

const DynamicTooltip: React.FC<{
	tooltipContent: React.ReactElement;
	children: React.ReactNode;
}> = ({ tooltipContent, children }) => {
	const [placement, setPlacement] = React.useState<'top' | 'right'>('top');
	const containerRef = React.useRef<HTMLDivElement>(null);

	// Helper to measure tooltip's natural height without showing it on screen.
	const measureTooltipHeight = React.useCallback((): number => {
		const tempDiv = document.createElement('div');
		tempDiv.style.visibility = 'hidden';
		tempDiv.style.position = 'absolute';
		tempDiv.style.top = '0';
		tempDiv.style.left = '0';
		tempDiv.innerHTML = ReactDOMServer.renderToStaticMarkup(tooltipContent);
		document.body.appendChild(tempDiv);
		const height = tempDiv.offsetHeight;
		document.body.removeChild(tempDiv);
		return height;
	}, [tooltipContent]);

	// On mouse enter, compute available space above and update placement accordingly.
	const handleMouseEnter = React.useCallback(() => {
		if (containerRef.current) {
			const rect = containerRef.current.getBoundingClientRect();
			const availableSpaceAbove = rect.top;
			const tooltipHeight = measureTooltipHeight();
			setPlacement(tooltipHeight > availableSpaceAbove ? 'right' : 'top');
		}
	}, [measureTooltipHeight]);

	return (
		<div onMouseEnter={handleMouseEnter} ref={containerRef}>
			<Tooltip message={tooltipContent} placement={placement}>
				{children}
			</Tooltip>
		</div>
	);
};

export default React.memo(DynamicTooltip);
