import * as React from 'react';
import type { CellContext } from '@tanstack/react-table';

import SimpleTableControl from 'af-components/Controls/SimpleTable';

import type ItemVM from 'ab-viewModels/item/edit.viewModel';
import type { ItemDepartmentVM } from 'ab-viewModels/item/edit.viewModel';

import type { SimpleTableProps } from 'af-components/Controls/SimpleTable/types';
import LabelWithColor from 'af-components/LabelWithColor';

import styles from './styles.module.scss';

interface OwnProps {
	item: ItemVM;
}

type Props = OwnProps;

const Inventory: React.FC<Props> = (props: Props) => {
	const {
		item,
	} = props;

	const renderLocation = React.useCallback((_cell: CellContext<ItemDepartmentVM, string>) => {
		return (
			<LabelWithColor color={_cell.row.original.color} text={_cell.row.original.locationName} />
		);
	}, []);

	const renderCurrentStock = React.useCallback((_cell: CellContext<ItemDepartmentVM, string>) => {
		if (_cell.row.original.minimumStock > _cell.row.original.currentStock) {
			return <div className={styles.danger}>{_cell.getValue()}</div>;
		}
		return _cell.getValue();
	}, []);

	const renderMinimumStock = React.useCallback((_cell: CellContext<ItemDepartmentVM, string>) => {
		if (_cell.row.original.minimumStock > _cell.row.original.currentStock) {
			return <div className={styles['minimal-quantity-danger']}>
				(<div className={styles.danger}>
					<span className="icon-arrow-down-bold" />
				</div>)
				<span className={styles['minimal-value']}>{_cell.getValue()}</span>
			</div>;
		}
		return _cell.getValue();
	}, []);

	const sortStringRow = (rowA, rowB, columnId) => {
		const a = rowA.getValue(columnId)?.toLowerCase() || '';
		const b = rowB.getValue(columnId)?.toLowerCase() || '';
		return a.localeCompare(b);
	};

	const columns: SimpleTableProps<ItemDepartmentVM>['columns'] = React.useMemo(() => [
		{
			id: 'locationName',
			cell: renderLocation,
			header: 'Location',
			accessor: 'locationName',
			enableSorting: true,
			sortingFn: sortStringRow,
			size: 500,
		},
		{
			id: 'name',
			cell: (_cell: CellContext<ItemDepartmentVM, string>) => {
				return _cell.getValue();
			},
			header: 'Department',
			accessor: 'name',
			enableSorting: true,
			sortingFn: sortStringRow,
			size: 500,
		},
		{
			id: 'currentStock',
			cell: renderCurrentStock,
			header: 'Current Stock',
			accessor: 'currentStock',
			enableSorting: false,
			size: 170,
		},
		{
			id: 'minimumStock',
			cell: renderMinimumStock,
			header: 'Minimum Quantity',
			accessor: 'minimumStock',
			enableSorting: false,
			size: 170,
		},
		{
			id: 'maximumStock',
			cell: (_cell: CellContext<ItemDepartmentVM, string>) => {
				return _cell.getValue();
			},
			header: 'Maximum Quantity',
			accessor: 'maximumStock',
			enableSorting: false,
			size: 170,
		},
	], [renderCurrentStock, renderLocation, renderMinimumStock]);

	return (
		<>
			<div className={styles.section}>
				<div className={styles.title}>Inventory</div>
				<div className={styles.restock}><span className={`icon-checkbox_${item.stockRegularly ? 'checked' : 'unchecked'}`} /> Restock regularly</div>
				<SimpleTableControl columns={columns} label="Item Inventory" rows={item.departments} />
			</div>
		</>
	);
};

export default React.memo(Inventory);
