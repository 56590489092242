import { isValidTextInput } from '@acceligentllc/shared/utils/text';

import type VendorRM from 'ab-requestModels/vendor/vendor.requestModel';

export function validate(values: VendorRM) {
	const errors: ValidationErrors = {};

	if (!isValidTextInput(values.name)) {
		errors.name = 'Name is required';
	}

	return errors;
}
