import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-constants/routes/client';

import * as ItemActions from 'af-actions/item';

import Breadcrumbs from 'af-components/Breadcrumbs';
import LoadingIndicator from 'af-components/LoadingIndicator';

import type ItemVM from 'ab-viewModels/item/edit.viewModel';

import Details from './Details';
import Inventory from './Inventory';
import VendorInfo from './VendorInfo';
import { isAllowed } from 'ab-utils/auth.util';
import PagePermissions from 'ab-enums/pagePermissions.enum';

interface PathParams {
	id: string;
}

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & ConnectedProps<typeof connector>;

const Preview = (props: Props) => {
	const {
		findById,
		match: { params: { id } },
		companyName,
		location: { state: { orgAlias } },
		hasManageAccessoriesPermission,
	} = props;

	const [loading, setLoading] = React.useState(false);
	const [item, setItem] = React.useState<Nullable<ItemVM>>(null);

	const loadStuff = React.useCallback(async () => {
		setLoading(true);
		const _item = await findById(+id);
		setItem(_item);
		setLoading(false);
	}, [findById, id]);

	React.useEffect(() => {
		loadStuff();
	}, [loadStuff]);

	return (
		<div>
			<Breadcrumbs
				items={[
					{ label: 'Equipment', url: CLIENT.COMPANY.EQUIPMENT.LIST(orgAlias, companyName) },
					{ label: 'Accessories', url: CLIENT.COMPANY.EQUIPMENT.ITEM.LIST(orgAlias, companyName) },
					{ label: 'Preview' },
				]}
			/>
			{(loading || !item) ? (
				<div className="form-box form-box--loading-only">
					<LoadingIndicator color="orange" size="big" />
				</div>
			) : (
				<>
					<Details companyName={companyName} hasManageAccessoriesPermission={hasManageAccessoriesPermission} item={item} />
					<Inventory item={item} />
					<VendorInfo item={item} />
				</>
			)}
		</div>
	);
};

function mapStateToProps(state: RootState) {
	const { companyData, userData } = state.user;
	if (!userData || !companyData) {
		throw new Error('User not logged in');
	}

	const hasManageAccessoriesPermission: boolean = isAllowed(
		PagePermissions.COMPANY.EQUIPMENT.ACCESSORIES.MANAGE,
		companyData.permissions,
		companyData.isCompanyAdmin,
		userData.role
	);

	return {
		companyName: companyData.name,
		hasManageAccessoriesPermission,
	};
}

function mapDispatchToProps() {
	return {
		findById: ItemActions.findById,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentClass<OwnProps>>(
	connector
);

export default enhance(Preview);
