import * as React from 'react';
import { compose } from 'redux';
import type { InjectedFormProps, FormErrorsWithArray } from 'redux-form';
import { reduxForm, Field, FieldArray, getFormValues, getFormSyncErrors } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import { Button } from '@acceligentllc/storybook';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { Option } from 'react-select/src/filters';
import type { FormatOptionLabelMeta } from 'react-select';

import PurchaseOrderStatus from '@acceligentllc/shared/enums/purchaseOrderStatus';
import { ColorHex } from '@acceligentllc/shared/enums/color';
import PurchaseOrderTruckPosition from '@acceligentllc/shared/enums/purchaseOrderTruckPosition';

import type { W_PurchaseOrder_FindPurchaseOrder_VM } from 'ab-api/web/purchaseOrder/findPurchaseOrder';

import * as ReduxFormKeys from 'af-constants/reduxForms';

import SubmitButton from 'af-components/SubmitButton';
import BadgeCell from 'af-components/Table6/Cells/BadgeCell';
import Label from 'af-components/LockedValue/Label';
import Label2 from 'af-components/Label';
import Breadcrumbs from 'af-components/Breadcrumbs';
import ConfirmationModal from 'af-components/ConfirmationModal';
import Form from 'af-components/Form';

import * as ItemCategoryActions from 'af-actions/itemCategory';
import * as LocationActions from 'af-actions/location';
import * as VendorActions from 'af-actions/vendor';
import * as WorkRequestActions from 'af-actions/workRequests';
import * as EquipmentActions from 'af-actions/equipment';
import * as ItemActions from 'af-actions/item';
import * as PurchaseOrderActions from 'af-actions/purchaseOrders';
import * as CompanyActions from 'af-actions/companies';
import ColorSquare from 'af-components/ColorSquare';

import Input from 'af-fields/Input';
import AsyncSelect from 'af-fields/AsyncSelect';
import Dropdown from 'af-fields/Dropdown';

import type VendorVM from 'ab-viewModels/vendor/vendor.viewModel';
import type ItemOptionVM from 'ab-viewModels/item/itemOption.viewModel';
import type EquipmentPOOptionVM from 'ab-viewModels/equipment/equipmentPurchaseOrderOption.viewModel';
import type WorkRequestOptionVM from 'ab-viewModels/workRequest/workRequestOption.viewModel';
import type UserVM from 'ab-viewModels/user/purchaseOrderOption.viewModel';

import { isAllowed } from 'ab-utils/auth.util';
import { moneyNormalizer } from 'ab-utils/formatting.util';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import type { RootState } from 'af-reducers';

import Select from 'af-fields/SelectField';

import CLIENT from 'af-routes/client';

import EquipmentOptionItem from '../../WorkOrders/Order/Form/ResourceLookups/Equipment/EquipmentOptionItem';
import { validate } from './validation';
import { PurchaseOrderFormModel } from './formModel';
import type { OwnProps as PurchaseOrderItemsOwnProps } from './purchaseOrderItems';
import PurchaseOrderItems from './purchaseOrderItems';

import styles from './styles.module.scss';

const getValue = (option: DropdownOption) => option.value;
const getLabel = (option: DropdownOption) => option.label;
const formatLabel = (option: DropdownOption) => <span>{option.label}</span>;

const renderEquipment = (equipment: EquipmentPOOptionVM) => (<EquipmentOptionItem {...equipment} searchText={''} />);

const highlightText = (text: string, highlight: string) => {
	const _lowerText = text?.toLowerCase?.();
	const _lowerHighLight = highlight?.toLowerCase?.();
	const index = _lowerText.indexOf(_lowerHighLight);

	if (!highlight || index === -1) {
		return <span>{text}</span>;
	}
	const _highlightText = text.slice(index, index + highlight.length);

	return (
		<>
			{text.split(_highlightText).map((_subtext, _index, _list) => (
				<span key={_index}>
					{_subtext}{_index + 1 !== _list.length && <mark>{_highlightText}</mark>}
				</span>)
			)}
		</>
	);
};

const renderJobMenuItem = (option: WorkRequestOptionVM, labelMeta: FormatOptionLabelMeta<WorkRequestOptionVM, boolean>) => {
	const { id, jobCode, isInternal, title, customerCompany, customerFormatted, travelLocationShort } = option;

	if (!id) {
		return (
			<div>
				<span className="work-order-upsert__job-picker-create-label">
					<span className="icon-plus" />
					Create New Job:
				</span>
				<span>{jobCode}</span>
			</div>
		);
	}

	if (labelMeta.selectValue?.[0]?.id === id) {
		return <strong>{jobCode}</strong>;
	}

	return (
		<div className="work-order-upsert__job-picker-option">
			<div>
				<strong>{highlightText(jobCode, labelMeta.inputValue)}</strong>
				<small><br /></small>
				{!!customerCompany && <small>{highlightText(customerCompany, labelMeta.inputValue)}</small>}
				{!!title && <small>{(!!customerCompany) && ' |'} {highlightText(title, labelMeta.inputValue)}</small>}
				{!!customerFormatted &&
					<small>
						{(!!title || !!customerCompany) && ' |'} {highlightText(customerFormatted, labelMeta.inputValue)}
					</small>
				}
				{!!travelLocationShort &&
					<small>
						{(!!customerFormatted || !!title || !!customerCompany) && ' |'} {highlightText(travelLocationShort, labelMeta.inputValue)}
					</small>
				}
			</div>
			{isInternal &&
				<BadgeCell
					badgeColor={ColorHex.BLACK}
					colorNegative={false}
					text="INTERNAL"
				/>
			}
		</div>
	);
};

const jobCodeFilterBy = (option: Option, text: string) => {
	let { jobCode, title, customerCompany, customerFormatted, travelLocationShort } = option.data;
	const { isInternal } = option.data;

	if (!text) {
		return true;
	}

	const searchText = text.toLowerCase();
	customerCompany = customerCompany ?? '';
	jobCode = jobCode?.toLowerCase() ?? '';
	title = title?.toLowerCase() ?? '';
	customerFormatted = customerFormatted?.toLowerCase() ?? '';
	travelLocationShort = travelLocationShort?.toLowerCase() ?? '';
	const jobTypeText = isInternal ? 'internal' : '';
	return jobCode.includes(searchText)
		|| title.includes(searchText)
		|| customerCompany.includes(searchText)
		|| customerFormatted.includes(searchText)
		|| travelLocationShort.includes(searchText)
		|| jobTypeText.includes(searchText);
};

const getNewOptionData = (inputValue: string) => {
	return { jobCode: inputValue };
};

const leavePageModalBody = <>
	You are about to leave this page without saving or submitting. <br />
	Changes that you made will not be saved.
</>;

const completePurchaseOrderModalBody = <>
	You are about to change the status of this Purchase Order to <i>Completed</i>. <br />
	This form will no longer be editable. Complete Purchase Order?
</>;

const getOptionValue = (option: WorkRequestOptionVM) => option.id;

const JobSelect = Select as unknown as new () => Select<WorkRequestOptionVM>;

const FILTER_BY: (keyof EquipmentPOOptionVM)[] = ['code', 'specification'];

const truckPositionOptions: DropdownOption[] = [
	{ label: 'N/A', value: PurchaseOrderTruckPosition.UNASSIGNED },
	{ label: 'Front', value: PurchaseOrderTruckPosition.FRONT },
	{ label: 'Rear', value: PurchaseOrderTruckPosition.REAR },
];

type DropdownOption = { label: string; value: number | string; };

type PathParams = {
	purchaseOrderId?: string;
};

type OwnProps = CustomRouteComponentProps<PathParams>;

type Props = OwnProps & InjectedFormProps<PurchaseOrderFormModel, OwnProps> & ConnectedProps<typeof connector>;

const PurchaseOrderForm: React.FC<Props> = (props: Props) => {
	const {
		handleSubmit,
		initialize,
		findCategories,
		findVendors,
		change,
		createVendor,
		findWorkRequestOptionsForPurchaseOrder,
		findEquipment,
		match: { params: { purchaseOrderId } },
		location: { state: { orgAlias }, search },
		history,
		findItems,
		findAllLocations,
		accountId,
		valid,
		destroy,
		createPurchaseOrder,
		updatePurchaseOrder,
		findPurchaseOrder,
		findCompanyUsers,
		companyName,
		deletePurchaseOrder,
		purchaseOrderItems,
		vendorId,
		status,
		locationId,
		requestedById,
		formOrderNumber,
		dateSubmitted,
		errors,
		initialized,
		canManage,
		touch,
		formValues,
		canCreate,
		canViewAndManageAll,
		itemCategoryId,
		companyHeaderUrl,
		companyLogoUrl,
		companyAddress,
		website,
	} = props;

	const [vendors, setVendors] = React.useState<DropdownOption[]>([]);
	const [categories, setCategories] = React.useState<DropdownOption[]>([]);
	const [equipment, setEquipment] = React.useState<EquipmentPOOptionVM[]>([]);
	const [companyUsers, setCompanyUsers] = React.useState<UserVM[]>([]);
	const [jobs, setJobs] = React.useState<WorkRequestOptionVM[]>([]);
	const [items, setItems] = React.useState<ItemOptionVM[]>([]);
	const [divisions, setDivisions] = React.useState<DropdownOption[]>([]);
	const [submittedBy, setSubmittedBy] = React.useState<Nullable<string>>(null);
	const [dateCompleted, setDateCompleted] = React.useState<Nullable<string>>(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
	const [isLeavePageModalOpen, setIsLeavePageModalOpen] = React.useState(false);
	const [isCompletePurchaseOrderModalOpen, setIsCompletePurchaseOrderModalOpen] = React.useState(false);
	const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false); // Can't use dirty or pristine since we use change with initialization
	const [purchaseOrderCreatedModalOpen, setPurchaseOrderCreatedModalOpen] = React.useState(false);
	const [orderNumber, setOrderNumber] = React.useState<string>('');
	const [originalPO, setOriginalPO] = React.useState<Nullable<W_PurchaseOrder_FindPurchaseOrder_VM>>(null);

	const BREADCRUMBS = React.useMemo(() => [
		{ label: 'Purchase Orders', url: CLIENT.COMPANY.PURCHASE_ORDER.TABLE(orgAlias, companyName) },
		{ label: !purchaseOrderId ? 'Create' : 'Edit' },
	], [companyName, orgAlias, purchaseOrderId]);
	const LOADING_BREADCRUMBS = React.useMemo(() => [{ label: 'Loading' }], []);

	const purchaseOrderCreatedModalBody = React.useMemo(() => <>
		Your purchase order has been created with Purchase Order Number {orderNumber}
	</>, [orderNumber]);

	const title = React.useMemo(() => {
		return !!orderNumber ? orderNumber : 'New Purchase Order';
	}, [orderNumber]);

	const queryParams = new URLSearchParams(search);
	const forPrint = queryParams.get('forPrint') === 'true';
	const initiatePrint = queryParams.get('initiatePrint') === 'true';
	const fullPrint = queryParams.get('fullPrint') === 'true';

	const canEdit = React.useMemo(() => {
		return canManage || canCreate || canViewAndManageAll;
	}, [canCreate, canManage, canViewAndManageAll]);

	const canComplete = React.useMemo(() => {
		return canManage || canViewAndManageAll;
	}, [canManage, canViewAndManageAll]);

	const handleEdit = React.useCallback(() => {
		const url = new URL(CLIENT.COMPANY.PURCHASE_ORDER.PREVIEW(orgAlias, companyName, `${purchaseOrderId}`), location.origin);

		history.push(url);
	}, [companyName, history, orgAlias, purchaseOrderId]);

	const iframeRef = React.useRef<HTMLIFrameElement>(null);

	const handlePrint = React.useCallback((isCondensed?: boolean) => () => {
		const iframe = iframeRef.current;

		// Find the print button by its ID
		const printButtonFull = document.getElementById('print-button-full');
		const printButtonCondensed = document.getElementById('print-button-condensed');
		const editButton = document.getElementById('edit-button');

		// Get all input fields
		const inputFields = document.querySelectorAll('input');

		// Store the original border styles to restore them later
		const originalInputStyles = Array.from(inputFields).map((input) => ({
			element: input,
			originalBorder: input.style.border,
		}));

		if (printButtonFull) {
			// Hide the print button before printing
			printButtonFull.style.display = 'none';
		}

		if (printButtonCondensed) {
			// Hide the print button before printing
			printButtonCondensed.style.display = 'none';
		}

		if (editButton) {
			// Hide the edit button before printing
			editButton.style.display = 'none';
		}

		// Set border to none for all input fields
		inputFields.forEach((input) => {
			(input as HTMLElement).style.border = 'none';
			(input as HTMLElement).style.top = '-10px';
		});

		if (iframe) {
			const iframeWindow = iframe.contentWindow;

			if (iframeWindow) {
				// Get the content to print
				const formElement = document.getElementById('form');
				if (!formElement) {
					console.error('Form element not found');
					return;
				}

				// Collect all <style> and <link rel="stylesheet"> elements from the main document
				const _styles = Array.from(document.querySelectorAll('style, link[rel="stylesheet"]'))
					.map((style) => style.outerHTML).join('');

				const websiteDiv = website ? `<div style="font-size: 12px; color: gray;">${website}</div>` : '';
				const companyAddressDiv = companyAddress ? `<div style="font-size: 12px; color: gray;">${companyAddress}</div>` : '';

				const header = companyHeaderUrl ?
					`<img src="${companyHeaderUrl}" alt="Company Header" style="width: 100%; padding-bottom: 10px"/>`
					: `<div style="display: flex; align-items: center; justify-content: start; padding-bottom: 10px;">
							<img src="${companyLogoUrl}" alt="Company Logo" style="width: 100px; height: 100px; margin-right: 10px;"/>
							<div style="text-align: left;">
								${websiteDiv}
								${companyAddressDiv}
							</div>
						</div>`;

				// Define the print-specific styles
				const printStyles = `
					@media print {
						/* Target classes using attribute selectors with partial matches */
						[class*="simple-table__body"] {
							display: table;
							width: 100%;
							table-layout: fixed;
						}

					${isCondensed ? `
							[class*="simple-table__header-cell"]:nth-child(7),
							[class*="simple-table__header-cell"]:nth-child(8),
							[class*="simple-table__header-cell"]:nth-child(9),
							[class*="simple-table__header-cell"]:nth-child(10),
							[class*="simple-table__cell"]:nth-child(7),
							[class*="simple-table__cell"]:nth-child(8),
							[class*="simple-table__cell"]:nth-child(9),
							[class*="simple-table__cell"]:nth-child(10)
							{
								display: none !important;
							}` : ''
					}

						[class*="form-title-container"],
						[class*="simple-table"],
						[class*="name-value"],
						[class*="field-value"],
						[class*="order-row"]
						{
							margin: 0px;
							padding: 0px;
							width: auto;
							gap:5px;
						}

						[class*="simple-table__row"],
						[class*="simple-table__header-row"] {
							${isCondensed ? 'grid-template-columns: 1fr 6fr 1fr 1fr 1fr 1fr !important;' : 'grid-template-columns: 2fr 6fr 2fr 3fr 3fr 2fr 2fr 2fr 2fr 2fr !important;'}
						}

						[class*="simple-table__cell"],
						[class*="simple-table__header-cell"] {
							display: table-cell;
							padding: 3px;
							width: auto;
							white-space: normal;
							word-wrap: break-word;
							overflow: hidden;
							text-overflow: ellipsis;
							font-size: 10px;
						}

						[class*="simple-table__label"] {
							display: none;
						}
	

						[class*="simple-table__header-cell"] {
							font-weight: bold;
							margin-left: -5px;
							whitespace: normal;
							word-wrap: break-word;
							text-overflow: ellipsis;
						}

					${!isCondensed ? `
							[class*="simple-table__header-cell"]:nth-child(3),
							[class*="simple-table__header-cell"]:nth-child(2)
							{
								margin-left: -15px;
							}` : ''
					}

						[class*="form__section"] {
							margin: 0;
							padding: 0;
						}

						.row {
							display: flex;
							flex-wrap: nowrap;
							justify-content: start;
						}

						@page {
							size: A4 portrait;
							margin: 10mm; 
						}
					}
				`;

				// Write the content into the iframe
				iframeWindow.document.open();
				iframeWindow.document.write('<html><head><title>Print</title>');
				iframeWindow.document.write(_styles); // Inject collected styles
				iframeWindow.document.write(`<style>${printStyles}</style>`); // Inject print-specific styles
				iframeWindow.document.write('</head><body>');
				iframeWindow.document.write(header); // Insert the company logo header image
				iframeWindow.document.write(formElement.innerHTML); // Inject content from formElement
				iframeWindow.document.write('</body></html>');
				iframeWindow.document.close();

				// Print when the iframe content is loaded
				iframeWindow.onload = () => {
					iframeWindow.focus();
					iframeWindow.print();
				};
			} else {
				console.error('Iframe contentWindow is not accessible');
			}
		} else {
			console.error('Iframe reference is null');
		}

		// Restore the print button after printing
		if (printButtonFull) {
			printButtonFull.style.display = 'block';
		}

		if (printButtonCondensed) {
			printButtonCondensed.style.display = 'block';
		}
		if (editButton) {
			editButton.style.display = 'block';
		}
		// Restore the original border style for input fields
		originalInputStyles.forEach((inputStyle) => {
			inputStyle.element.style.border = inputStyle.originalBorder;
			inputStyle.element.style.top = '0';
		});
	}, [companyAddress, companyHeaderUrl, companyLogoUrl, website]);

	React.useEffect(() => {
		if (purchaseOrderId) {
			setOrderNumber(formOrderNumber);
		}
	}, [formOrderNumber, purchaseOrderId]);

	const loadAndInit = React.useCallback(async () => {
		const [
			_categories,
			_vendors,
			_jobs,
			_equipment,
			_items,
			_divisions,
			_companyUsers,
		] = await Promise.all([
			findCategories(),
			findVendors(),
			findWorkRequestOptionsForPurchaseOrder(),
			findEquipment(),
			findItems(),
			findAllLocations(),
			findCompanyUsers(),
		]);

		if (!purchaseOrderId) {
			initialize({ status: PurchaseOrderStatus.UNFINISHED, frontOrRearOfTruck: PurchaseOrderTruckPosition.UNASSIGNED });
		} else {
			const po = await findPurchaseOrder(+purchaseOrderId);
			setOriginalPO(po);
			setSubmittedBy(po.submittedBy);
			setDateCompleted(po.dateCompleted);
			initialize(PurchaseOrderFormModel.fromViewModel(po));
			change('job', _jobs.find((j) => j.id === po.workRequestId));
		}

		const purchaseOrderItemsIds = purchaseOrderItems?.reduce((_acc, _curr) => {
			if (_curr.id) {
				_acc[_curr.id] = true;
			}
			return _acc;
		}, {} as Record<string, true>) ?? {};

		setCategories(_categories.map((_category) => ({ label: _category.name, value: _category.id })));
		setVendors(_vendors.map((_vendor) => ({ label: _vendor.name, value: _vendor.id })));
		setJobs(_jobs);
		setEquipment(_equipment);
		setCompanyUsers(_companyUsers);
		setItems(_items.filter((i) => !purchaseOrderItemsIds[i.id]));
		setDivisions(_divisions.map((d) => ({ label: d.nickname, value: d.id })));
	}, [
		findAllLocations,
		findCategories,
		findEquipment,
		findItems,
		findVendors,
		findWorkRequestOptionsForPurchaseOrder,
		purchaseOrderItems,
		purchaseOrderId,
		change,
		findPurchaseOrder,
		initialize,
		findCompanyUsers,
	]);

	React.useEffect(() => {

		loadAndInit();

		return () => {
			destroy();
		};
		// component did mount/component will unmount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (requestedById === undefined) {
			change('requestedById', accountId);
		}
		// We don't want change here
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountId, requestedById]);

	const close = React.useCallback(() => {
		history.push(CLIENT.COMPANY.PURCHASE_ORDER.TABLE(orgAlias, companyName));
	}, [companyName, history, orgAlias]);

	const closeDeleteModal = React.useCallback(() => {
		setIsDeleteModalOpen(false);
	}, [setIsDeleteModalOpen]);

	const openDeleteModal = React.useCallback(() => {
		setIsDeleteModalOpen(true);
	}, [setIsDeleteModalOpen]);

	const closeLeavePageModal = React.useCallback(() => {
		setIsLeavePageModalOpen(false);
	}, [setIsLeavePageModalOpen]);

	const openCompletePurchaseOrderModal = React.useCallback(() => {
		setIsCompletePurchaseOrderModalOpen(true);
	}, [setIsCompletePurchaseOrderModalOpen]);

	const closeCompletePurchaseOrderModal = React.useCallback(() => {
		setIsCompletePurchaseOrderModalOpen(false);
	}, [setIsCompletePurchaseOrderModalOpen]);

	const openLeavePageModal = React.useCallback(() => {
		setIsLeavePageModalOpen(true);
	}, [setIsLeavePageModalOpen]);

	const closePurchaseOrderCreatedModal = React.useCallback(() => {
		setPurchaseOrderCreatedModalOpen(false);
		close();
	}, [setPurchaseOrderCreatedModalOpen, close]);

	const openPurchaseOrderCreatedModal = React.useCallback(() => {
		setPurchaseOrderCreatedModalOpen(true);
	}, [setPurchaseOrderCreatedModalOpen]);

	const onClose = React.useCallback(() => {
		if (hasUnsavedChanges) {
			openLeavePageModal();
		} else {
			close();
		}
	}, [hasUnsavedChanges, openLeavePageModal, close]);

	const setUnsavedChanges = React.useCallback(() => {
		setHasUnsavedChanges(true);
	}, [setHasUnsavedChanges]);

	const deletePO = React.useCallback(async () => {
		if (purchaseOrderId) {
			await deletePurchaseOrder(+purchaseOrderId);
			close();
		}
	}, [purchaseOrderId, deletePurchaseOrder, close]);

	const deletePurchaseOrderModalFooter = React.useCallback(() => {
		return (
			<>
				<Button
					label="Cancel"
					onClick={closeDeleteModal}
					style="secondary"
				/>
				<Button
					label="Delete"
					onClick={deletePO}
					style="danger"
				/>
			</>
		);
	}, [closeDeleteModal, deletePO]);

	const deletePurchaseOrderModalBody = React.useCallback(() => {
		return (
			<>
				You are about to delete Purchase Order {orderNumber}.
			</>
		);
	}, [orderNumber]);

	const leavePageModalFooter = React.useCallback(() => {
		return (
			<>
				<Button
					label="Stay"
					onClick={closeLeavePageModal}
					style="secondary"
				/>
				<Button
					label="Leave"
					onClick={close}
					style="danger"
				/>
			</>
		);
	}, [closeLeavePageModal, close]);

	const handleSave = React.useCallback(async (form: PurchaseOrderFormModel) => {
		if (purchaseOrderId) {
			await updatePurchaseOrder(PurchaseOrderFormModel.toRM(form));
			close();
		} else {
			const _po = await createPurchaseOrder(PurchaseOrderFormModel.toRM(form));
			setOrderNumber(_po.orderNumber);
			openPurchaseOrderCreatedModal();
		}
	}, [purchaseOrderId, updatePurchaseOrder, close, createPurchaseOrder, openPurchaseOrderCreatedModal]);

	const complete = React.useCallback(async (form: PurchaseOrderFormModel) => {
		await handleSave({ ...form, status: PurchaseOrderStatus.COMPLETED });
	}, [handleSave]);

	const completePurchaseOrderModalFooter = React.useCallback(() => {
		return (
			<>
				<Button
					label="Cancel"
					onClick={closeCompletePurchaseOrderModal}
					style="secondary"
				/>
				<Button
					label="Complete"
					onClick={handleSubmit(complete)}
					style="danger"
				/>
			</>
		);
	}, [closeCompletePurchaseOrderModal, handleSubmit, complete]);

	const purchaseOrderCreatedModalFooter = React.useCallback(() => {
		return (
			<Button
				label="Understood"
				onClick={close}
				style="primary"
			/>
		);
	}, [close]);

	const submit = React.useCallback(async (form: PurchaseOrderFormModel) => {
		await handleSave({ ...form, status: PurchaseOrderStatus.OPENED });
	}, [handleSave]);

	const filterVendor = React.useCallback(async (key: string) => {
		if (!key) {
			return vendors;
		}
		return vendors.filter((_item) => {
			const name = _item.label.toLowerCase();
			return name.includes(key.toLowerCase());
		});
	}, [vendors]);

	const onVendorChange = React.useCallback(async (name: Nullable<string>) => {
		if (name) {
			const newVendor = await createVendor({ name });
			setVendors([...vendors, { label: newVendor.name, value: newVendor.id }]);
			change('vendor', { label: newVendor.name, value: newVendor.id });
			change('vendorId', newVendor.id);
		} else {
			change('vendor', null);
			change('vendorId', null);
		}
		// Trigger validation by marking the fields as touched
		touch('vendor', 'vendorId');
	}, [change, createVendor, vendors, touch]);

	const onVendorCreate = React.useCallback((name: string) => {
		onVendorChange(name);
	}, [onVendorChange]);

	const onVendorClear = React.useCallback(() => {
		onVendorChange(null);
	}, [onVendorChange]);

	const getNewVendor = React.useCallback((value: string, label: string) => {
		return { id: +value, name: label } as VendorVM;
	}, []);

	const onVendorPick = React.useCallback((vendor: DropdownOption) => {
		change('vendorId', vendor.value);
		setHasUnsavedChanges(true);
	}, [change]);

	const onJobChange = React.useCallback((job) => {
		change('workRequestId', job?.id ?? null);
		setHasUnsavedChanges(true);
	}, [change]);

	const defaultVendor = React.useMemo(() =>
		vendors.find((v) => v.value === vendorId) ?? (originalPO?.vendorId ? { label: originalPO.vendorName, value: originalPO.vendorId } : undefined)
		, [vendors, originalPO, vendorId]);

	const defaultDivision = React.useMemo(() => divisions.find((d) => d.value === locationId), [divisions, locationId]);

	const itemToChooseFrom = React.useMemo(() => {
		const purchaseOrderItemsIds = purchaseOrderItems?.reduce((_acc, _curr) => {
			if (_curr.id) {
				_acc[_curr.id] = true;
			}
			return _acc;
		}, {} as Record<string, true>) ?? {};
		return items.filter((i) => !purchaseOrderItemsIds[i.id]);
	}, [purchaseOrderItems, items]);

	const isReadOnly = React.useMemo(() => {
		return (
			status === PurchaseOrderStatus.COMPLETED
			|| (status === PurchaseOrderStatus.OPENED && !canEdit))
			|| !!forPrint;
	}, [status, canEdit, forPrint]);

	const statusValue = React.useMemo(() => status === PurchaseOrderStatus.UNFINISHED
		? 'Unfinished/ Not Submitted'
		: status?.charAt(0) + status?.substring(1)?.toLowerCase()
		, [status]);

	const totalCost = React.useMemo(() => purchaseOrderItems?.reduce((acc, poi) => {
		acc += poi.quantity && poi.priceEach ? poi.quantity * poi.priceEach : 0;
		return acc;
	}, 0), [purchaseOrderItems]);

	const vendorName = React.useMemo(() => {
		return vendors.find((v) => v.value === formValues?.vendorId)?.label ?? 'N/A';
	}, [formValues?.vendorId, vendors]);

	const categoryName = React.useMemo(() => {
		return categories.find((c) => c.value === formValues?.itemCategoryId)?.label ?? 'N/A';
	}, [categories, formValues?.itemCategoryId]);

	const divisionName = React.useMemo(() => {
		return divisions.find((d) => d.value === formValues?.locationId)?.label ?? 'N/A';
	}, [divisions, formValues?.locationId]);

	const requestedBy = React.useMemo(() => {
		return companyUsers.find((u) => u.companyAccountId === formValues?.requestedById)?.fullName ?? 'N/A';
	}, [companyUsers, formValues?.requestedById]);

	const notes = React.useMemo(() => {
		return formValues?.note ?? '-';
	}, [formValues?.note]);

	const equipmentOption = React.useMemo(() => {
		return equipment.find((i) => i.id === formValues?.equipmentId);
	}, [equipment, formValues?.equipmentId]);

	const truckPositionOption = React.useMemo(() => {
		return truckPositionOptions.find((i) => i.value === formValues?.frontOrRearOfTruck)?.label ?? 'N/A';
	}, [formValues?.frontOrRearOfTruck]);

	const job = React.useMemo(() => {
		return jobs.find((j) => j.id === formValues?.workRequestId)?.jobCode ?? 'N/A';
	}, [jobs, formValues?.workRequestId]);

	React.useEffect(() => {
		if (initialized && initiatePrint && purchaseOrderId) {
			// Add a delay of 500 milliseconds before triggering the print so that everything is loaded
			const timeoutId = setTimeout(() => {
				handlePrint(!fullPrint)();
			}, 500);

			return () => clearTimeout(timeoutId);
		}
	}, [initialized, initiatePrint, handlePrint, purchaseOrderId, fullPrint]);

	const disableFormSave = React.useMemo(() => {
		return status === PurchaseOrderStatus.OPENED
			? !valid || !formValues?.purchaseOrderItems?.length || !itemCategoryId || !vendorId // if submitted
			: !valid;
	}, [status, valid, formValues?.purchaseOrderItems?.length, itemCategoryId, vendorId]);

	const disableSubmitButton = React.useMemo(() => {
		return !valid || !formValues?.purchaseOrderItems?.length || !itemCategoryId || !vendorId;
	}, [valid, formValues?.purchaseOrderItems?.length, itemCategoryId, vendorId]);

	const forAccountNumberLabel = React.useMemo(() => (`For / Account Number${purchaseOrderId ? ' *' : ''}`), [purchaseOrderId]);

	if (!initialized) {
		return <Breadcrumbs items={LOADING_BREADCRUMBS} />;
	}

	return (
		<>
			{!isReadOnly && <Breadcrumbs items={BREADCRUMBS} />}
			<Form id="form">
				<Form.Header id={'form-header'} sticky={true}>
					<div className={styles['form-title']}>
						{title}
						{isReadOnly && !forPrint && (
							<span className={styles['form-title__readonly']}>
								(Read-Only)
							</span>
						)}
					</div>
					<div className={styles['form-button-container']}>
						{
							!forPrint &&
							<Button
								label={!isReadOnly ? 'Back to List' : 'Cancel'}
								onClick={onClose}
								style="secondary"
							/>
						}
						{
							forPrint &&
							<>
								<div id="print-button-full">
									<Button
										label="Print Full"
										onClick={handlePrint()}
										style="primary"
									/>
								</div>
								<div id="print-button-condensed">
									<Button
										label="Print Condensed"
										onClick={handlePrint(true)}
										style="primary"
									/>
								</div>
								<div id="edit-button">
									<Button
										label="Edit"
										onClick={handleEdit}
										style="primary"
									/>
								</div>
							</>
						}
						{
							status === PurchaseOrderStatus.UNFINISHED &&
							purchaseOrderId &&
							!forPrint && (
								<SubmitButton
									label="Delete"
									onClick={openDeleteModal}
									variant="secondary"
								/>
							)
						}
						{
							!isReadOnly &&
							<SubmitButton
								disabled={disableFormSave}
								label="Save"
								onClick={handleSubmit(handleSave)}
							/>
						}
						{
							canEdit &&
							status === PurchaseOrderStatus.UNFINISHED &&
							!forPrint && (
								<SubmitButton
									disabled={disableSubmitButton}
									label="Submit"
									onClick={handleSubmit(submit)}
									variant="primary"
								/>
							)
						}
						{
							(!isReadOnly && status === PurchaseOrderStatus.OPENED) &&
							canComplete &&
							(
								<SubmitButton
									disabled={disableFormSave}
									label="Complete"
									onClick={openCompletePurchaseOrderModal}
									variant="primary"
								/>
							)
						}

					</div>
				</Form.Header>
				<Form.Section title="purchase order details">
					<Row className={styles['order-row']}>
						<Col sm={8}>
							<div>Purchase Order Number</div>
							{
								!!orderNumber && <div className={styles['field-value']}>{orderNumber}</div>
							}
							{
								!orderNumber && (<div className={styles['field-value']}>Assigned after saving</div>)
							}
						</Col >
						<Col sm={4}>
							<div>Purchase Order Status</div>
							<Label2 className={status?.toLowerCase()} text={statusValue} />
						</Col>
						<Col sm={4}>
							<div>Date Submitted</div>
							<div className={styles['field-value']}>{dateSubmitted ?? 'N/A'}</div>
						</Col>
						<Col sm={4}>
							<div>Date Completed</div>
							<div className={styles['field-value']}>{dateCompleted ?? 'N/A'}</div>
						</Col>

						<Col sm={4}>
							<div className={styles['total-cost-container']}>
								<div className={`${styles['bold-grey']} ${styles.right}`}>Total Cost ($)</div>
								<div className={`${styles.bold} ${styles.right}`}>{moneyNormalizer(totalCost)}</div>
							</div>
						</Col>
					</Row >
					<Row className={styles['order-row']}>
						<Col sm={8}>
							{
								!isReadOnly &&
								<Field
									component={Input}
									label="Ship Via"
									name="shipVia"
									onChange={setUnsavedChanges}
								/>
							}
							{
								isReadOnly &&
								<>
									<div>Ship Via</div>
									<div className={styles['name-value']}>{formValues.shipVia ?? '-'}</div>
								</>
							}
						</Col>
						<Col sm={8}>
							{
								!isReadOnly &&
								<Field
									component={Input}
									label="Ship To"
									name="shipTo"
									onChange={setUnsavedChanges}
								/>
							}
							{
								isReadOnly &&
								<>
									<div>Ship To</div>
									<div className={styles['name-value']}>{formValues.shipTo ?? '-'}</div>
								</>
							}
						</Col>
						<Col sm={8}>
							{/* We do it like this because AsyncSelect can't be disabled */}
							{
								isReadOnly &&
								<>
									<div>Vendor</div>
									<div className={styles['name-value']}>{vendorName}</div>
								</>
							}
							{!isReadOnly &&
								<Field
									allowNew={true}
									component={AsyncSelect}
									defaultValue={defaultVendor}
									formatOptionLabel={formatLabel}
									getNewOptionData={getNewVendor}
									getOptionLabel={getLabel}
									getOptionValue={getValue}
									id="vendor"
									label="Vendor *"
									name="vendor"
									onClear={onVendorClear}
									onCreateNew={onVendorCreate}
									onSearch={filterVendor}
									onValueChange={onVendorPick}
									options={vendors}
									placeholder="" // if removed, it defaults to "Select..."
								/>
							}
						</Col>
					</Row>
					<Row className={styles['order-row']}>
						<Col sm={16}>
							{!isReadOnly &&
								<Field
									component={Dropdown}
									disabled={isReadOnly}
									filterable={true}
									filterBy={['label']}
									label={forAccountNumberLabel}
									labelKey="label"
									name="itemCategoryId"
									onChange={setUnsavedChanges}
									options={categories}
									valueKey="value"
									withCaret={true}
								/>
							}
							{
								isReadOnly &&
								<>
									<div>For / Account Number</div>
									<div className={styles['name-value']}>{categoryName}</div>
								</>
							}
						</Col>
						<Col sm={8}>
							{!isReadOnly &&
								<Field
									component={Dropdown}
									defaultValue={defaultDivision}
									label="Division"
									labelKey="label"
									name="locationId"
									onChange={setUnsavedChanges}
									options={divisions}
									valueKey="value"
									withCaret={true}
								/>
							}
							{
								isReadOnly &&
								<>
									<div>Division</div>
									<div className={styles['name-value']}>{divisionName}</div>
								</>
							}
						</Col>
					</Row>
					<Row className={styles['order-row']}>
						<Col sm={8}>
							{!isReadOnly &&
								<Field
									component={Dropdown}
									disabled={isReadOnly}
									filterable={true}
									filterBy={['fullName']}
									hasBlankOption={true}
									hideErrorText={true}
									isArrayField={true}
									label="Requested By"
									labelKey="fullName"
									name="requestedById"
									onChange={setUnsavedChanges}
									options={companyUsers}
									placeholder="Choose User"
									valueKey="companyAccountId"
									withCaret={true}
								/>
							}
							{
								isReadOnly &&
								<>
									<div>Requested by</div>
									<div className={styles['name-value']}>{requestedBy}</div>
								</>
							}
						</Col>
						<Col sm={6}>
							<div>Submitted by</div>
							<div className={styles['name-value']}>{submittedBy}</div>
						</Col>
					</Row>
					<Row className={styles['order-row']}>
						<Col sm={24}>
							{
								!isReadOnly &&
								<Field
									component={Input}
									label="Notes"
									maxCharacters={300}
									name="note"
									onChange={setUnsavedChanges}
								/>
							}
							{
								isReadOnly &&
								<>
									<div>Notes</div>
									<div className={styles['name-value']}>{notes}</div>
								</>
							}
						</Col>
					</Row>
				</Form.Section >
				<Form.Section title="delivery details">
					<Row className={styles['order-row']}>
						<Col sm={8}>
							{
								!isReadOnly &&
								<Field
									component={Dropdown}
									filterable={true}
									filterBy={FILTER_BY}
									hideErrorText={true}
									isArrayField={true}
									label="Equipment Code (Truck Number)"
									labelKey="code"
									name="equipmentId"
									onChange={setUnsavedChanges}
									options={equipment}
									placeholder="Choose equipment"
									renderMenuItem={renderEquipment}
									valueKey="id"
									withCaret={true}
								/>
							}
							{
								isReadOnly &&
								<>
									<div>Equipment Code (Truck Number)</div>
									{
										!equipmentOption &&
										<div className={styles['name-value']}>N/A</div>
									}
									{
										equipmentOption &&
										<div className={styles['row-value']}>
											<ColorSquare color={equipmentOption.equipmentCostCategoryColor} />
											<div className={styles['name-value']}>{equipmentOption.code} {equipmentOption.specification ? `(${equipmentOption.specification})` : ''}</div>
										</div>
									}
								</>
							}
						</Col>
						<Col sm={8}>
							{
								!isReadOnly &&
								<Field
									component={Dropdown}
									defaultValue={truckPositionOptions[0]}
									filterable={false}
									label="Front or Rear of Truck"
									labelKey="label"
									name="frontOrRearOfTruck"
									onChange={setUnsavedChanges}
									options={truckPositionOptions}
									valueKey="value"
									withCaret={true}
								/>
							}
							{
								isReadOnly &&
								<>
									<div>Front or Rear of Truck</div>
									<div className={styles['name-value']}>{truckPositionOption}</div>
								</>
							}
						</Col>
						<Col sm={8}>
							<Label
								label="Job Number"
								tooltipPlacement="top"
								withMargin={true}
							/>
							{
								!isReadOnly &&
								<Field
									allowNew={false}
									component={JobSelect}
									disableErrorMessage={true}
									filterOption={jobCodeFilterBy}
									formatOptionLabel={renderJobMenuItem}
									getNewOptionData={getNewOptionData}
									getOptionValue={getOptionValue}
									name="job"
									onValueChange={onJobChange}
									options={jobs}
									placeholder="Select Job"
								/>
							}
							{
								isReadOnly &&
								<div className={styles['name-value']}>{job}</div>
							}
						</Col>
					</Row>
				</Form.Section>
				<Form.Section title="items">
					<Row className={styles['order-row']}>
						<Col md={24}>
							<FieldArray<PurchaseOrderItemsOwnProps>
								availableItems={itemToChooseFrom}
								change={change}
								component={PurchaseOrderItems}
								errors={errors}
								initialized={initialized}
								isReadOnly={isReadOnly}
								name="purchaseOrderItems"
								setUnsavedChanges={setUnsavedChanges}
								vendorId={vendorId}
							/>
						</Col>
					</Row>
				</Form.Section>
			</Form >
			<ConfirmationModal
				body={deletePurchaseOrderModalBody()}
				closeModal={closeDeleteModal}
				footer={deletePurchaseOrderModalFooter()}
				modalStyle="danger"
				showModal={isDeleteModalOpen}
				size="md"
				title="Delete Purchase Order"
			/>
			<ConfirmationModal
				body={leavePageModalBody}
				closeModal={closeLeavePageModal}
				footer={leavePageModalFooter()}
				modalStyle="danger"
				showModal={isLeavePageModalOpen}
				size="md"
				title="Leave Page?"
			/>
			<ConfirmationModal
				body={completePurchaseOrderModalBody}
				closeModal={closeCompletePurchaseOrderModal}
				footer={completePurchaseOrderModalFooter()}
				modalStyle="danger"
				showModal={isCompletePurchaseOrderModalOpen}
				size="md"
				title="Complete Purchase Order?"
			/>
			<ConfirmationModal
				body={purchaseOrderCreatedModalBody}
				closeModal={closePurchaseOrderCreatedModal}
				footer={purchaseOrderCreatedModalFooter()}
				modalStyle="info"
				showModal={purchaseOrderCreatedModalOpen}
				size="md"
				title="Purchase Order created"
			/>
			{/* Hidden iframe for printing */}
			<iframe
				ref={iframeRef}
				style={{ display: 'none' }}
				title="print-iframe"
			></iframe>
		</>

	);
};

function mapStateToProps(state: RootState) {
	const { user: { companyData, userData } } = state;
	if (!companyData) {
		throw new Error('Company Data not present.');
	}
	if (!userData) {
		throw new Error('User Data not present.');
	}
	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	const formValues = getFormValues(ReduxFormKeys.PURCHASE_ORDER)(state) as PurchaseOrderFormModel;
	const errors = getFormSyncErrors(ReduxFormKeys.PURCHASE_ORDER)(state) as FormErrorsWithArray<PurchaseOrderFormModel, string>;

	return {
		purchaseOrderItems: formValues?.purchaseOrderItems,
		canManage: isAllowed(PagePermissions.COMPANY.PURCHASE_ORDER.MANAGE, permissions, isCompanyAdmin, role),
		canCreate: isAllowed(PagePermissions.COMPANY.PURCHASE_ORDER.CREATE, permissions, isCompanyAdmin, role),
		canViewAndManageAll: isAllowed(PagePermissions.COMPANY.PURCHASE_ORDER.VIEW_AND_MANAGE_ALL, permissions, isCompanyAdmin, role),
		vendorId: formValues?.vendorId,
		locationId: formValues?.locationId,
		requestedById: formValues?.requestedById,
		status: formValues?.status,
		orderNumber: formValues?.orderNumber,
		dateSubmitted: formValues?.dateSubmitted,
		accountId: companyData.accountId,
		companyName: companyData.name,
		formOrderNumber: formValues?.orderNumber,
		itemCategoryId: formValues?.itemCategoryId,
		errors,
		formValues,
		companyHeaderUrl: companyData.customPDFHeaderUrl,
		companyLogoUrl: companyData.logo,
		website: companyData.website,
		companyAddress: companyData.location,
	};
}

function mapDispatchToProps() {
	return {
		findCategories: ItemCategoryActions.findList,
		findAllLocations: LocationActions.findList,
		findVendors: VendorActions.findList,
		createVendor: VendorActions.create,
		findWorkRequestOptionsForPurchaseOrder: WorkRequestActions.findWorkRequestOptionsForPurchaseOrder,
		findEquipment: EquipmentActions.findForPurchaseOrderOptions,
		findItems: ItemActions.findAllForCompany,
		createPurchaseOrder: PurchaseOrderActions.createPurchaseOrder,
		updatePurchaseOrder: PurchaseOrderActions.updatePurchaseOrder,
		findPurchaseOrder: PurchaseOrderActions.findPurchaseOrder,
		deletePurchaseOrder: PurchaseOrderActions.deletePurchaseOrder,
		findCompanyUsers: CompanyActions.findCompanyUsers,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	reduxForm<PurchaseOrderFormModel, OwnProps>({
		form: ReduxFormKeys.PURCHASE_ORDER,
		validate,
	}),
	connector
);

export default enhance(PurchaseOrderForm);
