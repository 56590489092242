import * as React from 'react';
import { compose } from 'redux';
import type { InjectedFormProps } from 'redux-form';
import { reduxForm } from 'redux-form';

import type VendorRM from 'ab-requestModels/vendor/vendor.requestModel';

import type VendorVM from 'ab-viewModels/vendor/vendor.viewModel';

import { VENDOR_CREATE } from 'af-constants/reduxForms';

import Form from './VendorForm';
import { validate } from './validate';

interface OwnProps {
	create: (form: VendorRM) => Promise<void>;
	close: () => void;
	vendors: VendorVM[];
	id: Nullable<number>;
}

type Props = OwnProps & InjectedFormProps<VendorRM, OwnProps>;

const VendorCreate = (props: Props) => {
	const { close, create } = props;
	const onCreate = React.useCallback(async (form: VendorRM) => {
		await create(form);
		close();
	}, [close, create]);

	return <Form onSubmit={onCreate} {...props} />;
};

const enhance = compose<React.ComponentType<OwnProps>>(
	React.memo,
	reduxForm<VendorRM, OwnProps>({ form: VENDOR_CREATE, validate, enableReinitialize: true })
);

export default enhance(VendorCreate);
