// Platform
export const PLATFORM_ORGANIZATION_CREATE = 'createNewOrganizationForm';
export const PLATFORM_ORGANIZATION_NOTIFY = 'notifyAllOrganizaitonEmployeesForm';
export const PLATFORM_SYSTEM_NOTIFICATION_CREATE = 'systemNotificationForm';

// User
export const USER_EMAIL_LOGIN = 'emailLoginForm';
export const USER_PHONE_LOGIN = 'phoneLoginForm';
export const USER_LOGIN_CODE = 'codeLoginForm';
export const USER_FINALIZE = 'finalizeUserForm';
export const USER_PASSWORD_FORGOTTEN = 'passwordForgottenForm';
export const USER_RESET_PASSWORD = 'resetPasswordForm';
export const USER_SAVE_DIGITAL_SIGNATURE = 'saveDigitalSignature';

// Organization
export const ORGANIZATION_CHECK = 'organizationCheckForm';
export const ORGANIZATION_FORGOTTEN = 'organizationForgottenForm';

// Account
export const VERIFY_EMAIL = 'verifyEmailForm';
export const ACCOUNT_EDIT = 'editAccount';

// Settings
export const COMPANY_CREATE = 'createCompanyForm';
export const COMPANY_EDIT = 'editCompanyForm';
export const COMPANY_EHS_EDIT = 'editCompanyEHSForm';
export const COMPANY_WORK_DAYS_EDIT = 'editCompanyWorkDays';
export const AUTOMATIC_NOTIFICATIONS = 'editAutomaticNotificationsForm';
export const GENERAL_NOTIFICATIONS = 'editGeneralNotificationsForm';
export const TEMPORARY_LABOR_NOTIFICATIONS = 'editTemporaryLaborNotificationsForm';
export const DAILY_NOTIFICATIONS_SCHEDULE = 'editDailyNotificationsForm';
export const SPECIAL_EMAIL_NOTIFICATIONS = 'editSpecialEmailNotificationsForm';
export const SCHEDULE_BOARD_SETTINGS = 'editScheduleBoardSettingsForm';
export const WORK_ORDER_SETTINGS = 'editWorkOrderSettingsForm';
export const FIELD_REPORT_SETTINGS = 'editFieldReportSettingsForm';
export const REPORT_TYPE = 'createReportType';
export const REPORT_BLOCK = 'createReportBlock';
export const REPORT_TYPE_BLOCK = 'addReportTypeBlock';
export const EDIT_BLOCK_NAME = 'editBlockName';
export const REPORT_BLOCK_FIELD = 'createReportBlockField';
export const REPORT_BLOCK_CALCULATED_FIELD = 'createReportBlockCalculatedField';
export const REPORT_BLOCK_PREVIEW = 'reportBlockPreview';
export const EQUIPMENT_SETTINGS = 'equipmentSettings';
export const LABOR_SETTINGS = 'laborSettings';
export const BILLABLE_WORK = 'billableWork';

// Field report
export const ADD_REPORT_TYPE = 'addReportType';
export const SUBMIT_FIELD_REPORT = 'submitFieldReport';
export const FIELD_REPORT = 'fieldReport';
export const SUBMIT_FOR_CUSTOMER_APPROVAL = 'submitForCustomerApproval';
export const DOWNLOAD_FIELD_REPORT_CONFIRMATION = 'downloadFieldReportConfirmation';
export const SIGNATURE_FIELD_REPORT = 'signatureFieldReport';
export const TIME_SHEET = 'timeSheetForm';
export const TIME_SHEET_EQUIPMENT = 'timeSheetEquipmentForm';
export const TIME_SHEET_NOTE = 'timeSheetNoteForm';
export const FIELD_REPORT_ACCESS = 'fieldReportAccess';
export const REJECT_REPORT = 'rejectReportForm';
export const CONDENSED_TABLE_EMPLOYEE = 'condensedTableEmployee';

// Communication
export const COMPANY_NOTIFY = 'notifyAllCompanyEmployeesForm';
export const SHORTEN_URL = 'shortenUrlForm';

// Contact
export const CONTACT_CREATE = 'createContactForm';
export const CONTACT_CREATE_MODAL = 'createContactModalForm';
export const CONTACT_EDIT = 'editContactForm';
export const CONTACT_BULK_IMPORT = 'bulkImportContactForm';
export const CONTACT_ACTION_MODAL_NEW_CONTACT = 'contactActionModalNewContactForm';
export const CONTACT_ACTION_MODAL_SAVED_CONTACT = 'contactActionModalSavedContactForm';

// Request
export const REQUEST_ROOT = 'requestRootForm';
export const REQUEST_BILLING = 'requestBillingForm';
export const REQUEST_BILLING_CONTACT = 'requestBillingContactForm';
export const REQUEST_CUSTOMER_INFO = 'requestCustomerInfoForm';
export const REQUEST_CUSTOMER_CONTACT = 'requestCustomerContactForm';
export const REQUEST_REQUIREMENTS = 'requestRequirementsForm';
export const REQUEST_OTHER_CONTACTS = 'requestOtherContactsForm';
export const REQUEST_LOCATION = 'requestLocationForm';
export const REQUEST_LOCATION_CONTACT = 'requestLocationContactForm';
export const REQUEST_NOTES = 'requestNotesForm';
export const REQUEST_ATTACHMENT = 'requestAttachmentForm';
export const REQUEST_SALES = 'requestSalesForm';
export const REQUEST_SCOPE_OF_WORK = 'requestScopeOfWorkForm';
export const REQUEST_SCHEDULE = 'requestScheduleForm';
export const REQUEST_INITIALIZATION = 'requestInitializationForm';
export const REQUEST_SUBMIT = 'requestSubmit';

// Approval
export const DOWNLOAD_PROPOSAL = 'downloadProposal';
export const GENERATE_PROPOSAL = 'generateProposal';
export const APPROVAL_REQUEST = 'approveRequestForm';
export const APPROVAL_REQUEST_RADIO = 'approveRequestFormRadioGroup';
export const APPROVAL_REQUEST_SUBMIT = 'approvalRequestSubmit';
export const APPROVAL_DOCUMENT_SELECTION = 'approvalDocumentSelection';

// Estimate
export const ESTIMATE_TASKS = 'estimateTasksForm';
export const ESTIMATE_TASK = 'estimateTaskForm';
export const ESTIMATE_SUBMIT = 'estimateSubmit';

// Review
export const REVIEW_TASKS = 'reviewTasksForm';
export const REVIEW_SUBMIT = 'reviewSubmit';

// Settings
export const MEMBER_INVITE = 'inviteMemberForm';
export const MEMBER_EDIT = 'editMemberForm';
export const MEMBERS_INVITE_PERMISSIONS = 'inviteMembersPermissionsForm';
export const MEMBERS_INVITE_BATCH = 'inviteMembersBatchForm';

// Resources
export const AGENCY_CREATE = 'createAgencyForm';
export const AGENCY_EDIT = 'editAgencyForm';
export const WAGE_RATE_CREATE = 'createWageRateForm';
export const WAGE_RATE_EDIT = 'editWageRateForm';
export const WAGE_RATE_IMPORT = 'importWageRatesForm';
export const FIELD_WORK_CLASSIFICATION_CODE_CREATE = 'createFieldWorkClassificationCodeForm';
export const FIELD_WORK_CLASSIFICATION_CODE_EDIT = 'editFieldWorkClassificationCodeForm';
export const RESOURCE_STATUS_CREATE = 'createResourceStatusForm';
export const EMPLOYEE_EDIT = 'editEmployeeForm';
export const EMPLOYEE_CREATE = 'createEmployeeForm';
export const EMPLOYEE_IMPORT = 'importEmployeeForm';
export const ITEM_CREATE = 'createItemForm';
export const ITEM_UPDATE = 'updateItemForm';
export const TOOL_CREATE = 'createToolForm';
export const TOOL_UPDATE = 'updateToolForm';
export const TOOL_REPAIR_CREATE = 'createToolRepairForm';
export const TOOL_REPAIR_UPDATE = 'updateToolRepairForm';
export const TOOL_CHANGE_LOCATION = 'changeToolLocationForm';
export const EQUIPMENT_CREATE = 'createEquipmentForm';
export const EQUIPMENT_EDIT = 'editEquipmentForm';
export const EQUIPMENT_IMPORT = 'importEquipmentCostsForm';
export const EQUIPMENT_COST_CREATE = 'createEquipmentCostForm';
export const EQUIPMENT_COST_EDIT = 'editEquipmentCostForm';
export const EQUIPMENT_COST_IMPORT = 'importEquipmentCostsForm';
export const EQUIPMENT_COST_FILTER = 'equipmentCostFilterBox';
export const EQUIPMENT_COST_CATEGORY_CREATE = 'createEquipmentCostCategoryForm';
export const CREW_TYPE_CREATE = 'createCrewTypeForm';
export const CREW_TYPE_EDIT = 'editCrewTypeForm';
export const DIVISION_CREATE = 'createDivisionForm';
export const DIVISION_EDIT = 'editDivisionForm';
export const ITEM_CATEGORY_CREATE = 'createItemCategory';
export const ITEM_CATEGORY_EDIT = 'editItemCategory';
export const VENDOR_EDIT = 'editVendor';
export const VENDOR_CREATE = 'createVendor';
export const SKILL_CREATE = 'createSkillForm';
export const SKILL_EDIT = 'editSkillForm';
export const LOCATION_CREATE = 'createLocationForm';
export const LOCATION_EDIT = 'editLocationForm';
export const JOB_STATUS_CREATE = 'createJobStatusForm';
export const JOB_STATUS_EDIT = 'editJobStatusForm';
export const SALE_CREATE = 'createSaleForm';
export const SALE_EDIT = 'editSaleForm';
export const DOCUMENTS = 'uploadDocuments';
export const SORT_LOCATION_FORM = 'sortLocationForm';
export const LOADING_LOCATIONS = 'loadingLocations';
export const DELIVERABLE_STATUS = 'deliverableStatus';
export const TAG = 'tag';
export const RESOURCE_STATUS = 'resourceStatus';
export const TEMPORARY_EMPLOYEE_CREATE = 'temporaryEmployeeCreate';
export const TEMPORARY_EMPLOYEE_EDIT = 'temporaryEmployeeEdit';

// Jobs and work orders
export const WORK_ORDER_FORM = 'workOrderForm';
export const JOB_PARAMETERS = 'jobParametersForm';
export const ORDER_LIST_FORM = 'orderListForm';
export const ORDER_COPY_FORM = 'orderCopyForm';
export const ORDER_DELAY_FORM = 'orderDelayForm';
export const ORDER_CANCEL_FORM = 'orderCancelForm';
export const ORDER_PAUSE_FORM = 'orderPauseForm';
export const DOWNLOAD_ORDER_CONFIRMATION = 'downloadOrderConfirmation';
export const SAVE_WORK_ORDER = 'saveWorkOrderForm';
export const DELETE_WORK_ORDER = 'deleteWorkOrderForm';
export const JOB_CREATE = 'jobCreateForm';
export const JOB_EDIT = 'jobEditForm';
export const JOB_COPY = 'jobCopyForm';
export const JOB_FORM = 'jobForm';
export const JOB_CUSTOMER_CONTACT = 'jobCustomerContactForm';
export const JOB_SITE_CONTACT = 'jobSiteContactForm';
export const WORK_SUMMARY_BILLABLE_WORK = 'workSummaryBillableWork';
export const INVOICE_CREATE_FORM = 'invoiceCreateForm';

// Accounting
export const PURCHASE_ORDER = 'purchaseOrder';
export const INVOICE_JOB_SELECT_FORM = 'invoiceJobSelectForm';

// Work Request
export const WORK_REQUEST_FORM = 'workRequestForm';
export const CONVERT_TO_JOB = 'convertToJob';
export const WORK_REQUEST_COPY = 'workRequestCopy';

// Schedule Board
export const EXPORT_SCHEDULE_BOARD = 'exportScheduleBoard';
export const REMOVE_BLANK_WORK_ORDER = 'removeBlankWorkOrder';
export const UPDATE_DAILY_VIEW_METRICS = 'updateDailyViewMetrics';
export const LABOR_PLACEHOLDER_FORM = 'createLaborPlaceholder';
export const EQUIPMENT_PLACEHOLDER_FORM = 'createEquipmentPlaceholder';
export const SEND_SB_TEMPLATE_FORM = 'sendScheduleBoardTemplate';
export const SCHEDULE_SB_TEMPLATE_EMAIL = 'scheduleScheduleBoardTemplate';
export const WORK_ORDER_NOTE = 'workOrderNote';
export const SET_PER_DIEM_FOR_WORK_ORDERS = 'setPerDiemForWorkOrders';
export const DAILY_TIP_FORM = 'dailyTipForm';
export const DAILY_PER_DIEM_TIP_FORM = 'dailyPerDiemTipForm';
export const EQUIPMENT_DOWN_FORM = 'equipmentDownForm';
export const EMPLOYEE_DOWN_FORM = 'employeeDownForm';
export const TEMPORARY_EMPLOYEE_ASSIGNMENT = 'createTemporaryEmployeeAssignment';

// Deliverable
export const DELIVERABLE = 'deliverable';
export const DELIVERABLE_SUBMISSION = 'deliverableSubmission';
export const BULK_DELIVERABLE_SUBMISSION = 'bulkDeliverableSubmission';
export const DELIVERABLE_ASSIGNMENT = 'deliverableAssignment';

// Orders
export const ORDER_FORM = 'orderForm';

// Logs
export const DOWNLOAD_LOGS = 'downloadLogs';

// User Groups
export const USER_GROUP_FORM = 'userGroupForm';
