import * as React from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { CompatRoute, useLocation } from 'react-router-dom-v5-compat';
import Scrollbars from 'react-custom-scrollbars';
import { ToastContainer } from 'react-toastify';
import * as ReactGA from 'react-ga';

import DisabledFeatures from 'ab-common/environment/disabledFeatures';

import { isCurrentPathPublic } from 'af-root/store/history';

import RequireAuth from 'af-utils/requireAuthentication.util';
import { getCurrentOrgAlias, getCompanyNameInPath } from 'af-utils/window.util';
import RestrictAuthenticated from 'af-utils/restrictAuthenticated';
import { withPermission } from 'af-utils/PermissionManagement';
import { getSignInForOrg } from 'af-utils/localStorage.util';

import type { RootState } from 'af-reducers';

import CLIENT from 'af-routes/client';

import PagePermissions from 'ab-enums/pagePermissions.enum';

import * as GoogleAnalyticsActions from 'af-actions/googleAnalytics';
import * as AuthenticationActions from 'af-actions/authentication';
import * as OrganizationsActions from 'af-actions/organizations';

import './styles/App.scss';
import 'react-notion-x/src/styles.css';
import 'prismjs/themes/prism-tomorrow.css';
import 'katex/dist/katex.min.css';

// Components
import LoadingIndicator from 'af-components/LoadingIndicator';
import Header from 'af-components/Header';
import * as SideNav from 'af-components/SideNav';
import * as FloatingSideNav from 'af-components/FloatingSideNav';
import HomeRedirect from 'af-components/HomeRedirect';

import { APP_SCROLLBARS_ID, __ENVIRONMENT__ } from 'af-constants/values';

// Privacy policy and TOS
import PrivacyPolicy from 'af-root/scenes/Platform/PrivacyPolicy';
import TermsOfService from 'af-root/scenes/Platform/TermsOfService';

// Errors
import Error403Container from 'af-root/scenes/Error/error403';
import Error404Container from 'af-root/scenes/Error/error404';
import Error500Container from 'af-root/scenes/Error/error500';

// Auth pages
import LoginContainer from 'af-root/scenes/Authentication/Login';
import CodeLoginContainer from 'af-root/scenes/Authentication/CodeLogin';
import PlatformAdminLoginContainer from 'af-root/scenes/Authentication/PlatformAdminLogin';
import PasswordOutdatedContainer from 'af-root/scenes/Authentication/PasswordOutdated';
import FinalizeUserContainer from 'af-root/scenes/Authentication/FinalizeUser';
import LogoutContainer from 'af-root/scenes/Authentication/Logout';
import PasswordForgottenContainer from 'af-root/scenes/Authentication/PasswordForgotten';
import PasswordForgottenSuccessContainer from 'af-root/scenes/Authentication/PasswordForgottenSuccess';
import ResetPasswordContainer from 'af-root/scenes/Authentication/ResetPassword';
import ResetPasswordSuccessContainer from 'af-root/scenes/Authentication/ResetPasswordSuccess';
import ChooseOrganizationContainer from 'af-root/scenes/Authentication/ChooseOrganization';
import ForgotOrganizationContainer from 'af-root/scenes/Authentication/ForgotOrganization';
import ForgotOrganizationSuccessContainer from 'af-root/scenes/Authentication/ForgotOrganizationSuccess';

// Platform pages
import AllOrganizationsContainer from 'af-root/scenes/Platform/AllOrganizations';
import CreateOrganizationContainer from 'af-root/scenes/Platform/CreateOrganization';
import NotifyOrganizationEmployeesContainer from 'af-root/scenes/Platform/NotifyOrganizationEmployees';
import SystemNotificationsContainer from 'af-root/scenes/Platform/SystemNotifications';
import OrganizationUsersContainer from 'af-root/scenes/Platform/Members/List';

// Organization pages
import OrganizationSettingsContainer from 'af-root/scenes/Organization/Settings';
import OrganizationLogs from 'af-root/scenes/Organization/Settings/Logs/Table';
import OrganizationLogContainer from 'af-root/scenes/Organization/Settings/Logs/Preview';
import OrganizationMembersContainer from 'af-root/scenes/Organization/Members';

// Knowledge center
import KnowledgeCenter from 'af-root/scenes/Company/KnowledgeCenter';

// Company pages
import CreateCompanyContainer from 'af-root/scenes/Company/CreateCompany';

import Compliance from 'af-root/scenes/Company/Compliance';

import CompanyDashboard from 'af-root/scenes/Company/Dashboard';

import CompanyAnalyticsEquipmentUtilization from 'af-root/scenes/Company/Analytics/EquipmentUtilization';
import CompanyAnalyticsLaborUtilization from 'af-root/scenes/Company/Analytics/LaborUtilization';

import CompanySettingsCompanyMembersListContainer from 'af-root/scenes/Company/Settings/Members/List';
import CompanySettingsCompanyMembersBulkUploadContainer from 'af-root/scenes/Company/Settings/Members/BulkUpload';
import CompanySettingsCompanyMembersInviteContainer from 'af-root/scenes/Company/Settings/Members/Invite';
import CompanySettingsCompanyMembersRolesAndPermissionsContainer from 'af-root/scenes/Company/Settings/Members/RolesAndPermissions';
import CompanySettingsCompanyMemberEditContainer from 'af-root/scenes/Company/Settings/Members/Edit';

import CompanyNotificationCenterContainer from 'af-root/scenes/Company/Communication/NotifyEmployees';
import CompanyUrlShortenerContainer from 'af-root/scenes/Company/Communication/UrlShortener';
import CompanyCommunicationHistoryContainer from 'af-root/scenes/Company/Communication/CommunicationHistory';
import CompanyMessagePreviewContainer from 'af-root/scenes/Company/Communication/CommunicationHistory/MessagePreview';

import CompanySettingsAccountContainer from 'af-root/scenes/Company/Settings/Account';
import CompanySettingsNotificationContainer from 'af-root/scenes/Company/Settings/Notification';
import CompanySettingsCompanyContainer from 'af-root/scenes/Company/Settings/Company';
import CompanySettingsScheduleBoardContainer from 'af-root/scenes/Company/Settings/ScheduleBoard';
import CompanySettingsWorkOrderContainer from 'af-root/scenes/Company/Settings/WorkOrder';
import CompanyContactListContainer from 'af-root/scenes/Company/Contacts/List';
import CompanyContactCreateContainer from 'af-root/scenes/Company/Contacts/Create';
import CompanyContactEditContainer from 'af-root/scenes/Company/Contacts/Edit';
import CompanyContactPreviewContainer from 'af-root/scenes/Company/Contacts/Preview';
import CompanyContactBulkUploadContainer from 'af-root/scenes/Company/Contacts/BulkUpload';
import CompanyLogsListContainer from 'af-root/scenes/Company/Settings/Logs/List';
import CompanyLogContainer from 'af-root/scenes/Company/Settings/Logs/Preview';
import CompanyUserGroupsListContainer from 'af-root/scenes/Company/Settings/UserGroups/Table';
import CompanyUserGroupFormContainer from 'af-root/scenes/Company/Settings/UserGroups/UserGroup';
import CompanySettingsReportContainer from 'af-root/scenes/Company/Settings/Reports/List';
import CompanySettingsCreateReportBlockContainer from 'af-root/scenes/Company/Settings/Reports/ReportBlock/Create';
import CompanySettingsEditReportBlockContainer from 'af-root/scenes/Company/Settings/Reports/ReportBlock/Edit';
import CompanySettingsCreateCustomReportTypeContainer from 'af-root/scenes/Company/Settings/Reports/ReportType/CreateCustom';
import CompanySettingsEditCustomReportTypeContainer from 'af-root/scenes/Company/Settings/Reports/ReportType/EditCustom';
import CompanySettingsEquipmentContainer from 'af-root/scenes/Company/Settings/Equipment';
import CompanySettingsLaborContainer from 'af-root/scenes/Company/Settings/Labor';

// Job Pages
import CompanyJobsTableContainer from 'af-root/scenes/Company/Jobs/Table';
import CompanyJobsDashboardContainer from 'af-root/scenes/Company/Jobs/JobsDashboard';
import CompanyJobCreateRouter from 'af-root/scenes/Company/Jobs/Form/Create/router';
import CompanyJobEditRouter from 'af-root/scenes/Company/Jobs/Form/Edit/router';
import CompanyJobPreviewRouter from 'af-root/scenes/Company/Jobs/Preview/router';
import CompanyJobInvoiceCreateOrEditContainer from 'af-root/scenes/Company/Jobs/Preview/Invoices/CreateOrEdit';
import CompanyJobInvoicedInvoiceContainer from 'af-root/scenes/Company/Jobs/Preview/Invoices/EditWhenInvoiced';

// Work Request Pages
import CompanyWorkRequestTable from 'af-root/scenes/Company/WorkRequests/Table';
import CompanyWorkRequestFormRouter from 'af-root/scenes/Company/WorkRequests/Form/router';
import CompanyWorkRequestPreviewRouter from 'af-root/scenes/Company/WorkRequests/Preview/router';

// Work Order Pages
import CompanyOrderContainer from 'af-root/scenes/Company/WorkOrders/Order';
import CompanyOrderConfirmationContainer from 'af-root/scenes/Company/WorkOrders/ConfirmationPrivate';
import CompanyOrderConfirmationPublicContainer from 'af-root/scenes/Company/WorkOrders/ConfirmationPublic';
import CompanyOrderConfirmationPersonalUnassignedContainer from 'af-root/scenes/Company/WorkOrders/ConfirmationPersonalUnassigned';
import CompanyOrderConfirmationPersonalContainer from 'af-root/scenes/Company/WorkOrders/ConfirmationPersonal';

// Orders
import CompanyOrdersTable from 'af-root/scenes/Company/Orders/Table';
import CompanyOrdersForm from 'af-root/scenes/Company/Orders/Form';

// Schedule Board Pages
import CompanyScheduleBoardDailyViewContainer from 'af-root/scenes/Company/ScheduleBoard/DailyView';
import CompanyScheduleBoardWeeklyViewContainer from 'af-root/scenes/Company/ScheduleBoard/WeeklyView';
import CompanyScheduleBoardDisplayViewRotationContainer from 'af-root/scenes/Company/ScheduleBoard/DisplayView';
import CompanyScheduleBoardMechanicViewContainer from 'af-root/scenes/Company/ScheduleBoard/MechanicView';
import CompanySmsNotificationConfirmationContainer from 'af-root/scenes/Company/ScheduleBoard/SmsNotificationConfirmation';

// Field Report Pages
import CompanyFieldReportsPublicReportContainer from 'af-root/scenes/Company/FieldReports/PublicReport';

// Components
import Loading from 'af-components/Loading';

// Accounting
import CompanyAccountingPerDiemReportList from 'af-root/scenes/Company/Accounting/PerDiemReport/List';
import CompanyAccountingPrevailingWages from 'af-root/scenes/Company/Accounting/PrevailingWages';
import CompanyAccountingPrevailingWagesExceptionReport from 'af-root/scenes/Company/Accounting/PrevailingWagesExceptionReport';
import CompanyAccountingJobPayrollReportBeta from 'af-root/scenes/Company/Accounting/JobPayrollReportBeta';
import CompanyAccountingJobWorkSummary from 'af-root/scenes/Company/Accounting/JobWorkSummary';
import CompanyAccountingPurchaseOrder from 'af-root/scenes/Company/Accounting/PurchaseOrder';
import CompanyAccountingPurchaseOrderPreview from 'af-root/scenes/Company/Accounting/PurchaseOrder/purchaseOrderForm';
import CompanyAccountingInvoicesTable from 'af-root/scenes/Company/Accounting/Invoices';

// Resources
import CompanyResourcesAgencyList from 'af-root/scenes/Company/Resources/Agency/List';
import CompanyResourcesAgencyCreate from 'af-root/scenes/Company/Resources/Agency/Create';
import CompanyResourcesAgencyEdit from 'af-root/scenes/Company/Resources/Agency/Edit';
import CompanyResourcesAgencyPreview from 'af-root/scenes/Company/Resources/Agency/Preview';
import CompanyResourcesWageRateList from 'af-root/scenes/Company/Resources/WageRate/List';
import CompanyResourcesWageRateCreate from 'af-root/scenes/Company/Resources/WageRate/Create';
import CompanyResourcesWageRateEdit from 'af-root/scenes/Company/Resources/WageRate/Edit';
import CompanyResourcesWageRatePreview from 'af-root/scenes/Company/Resources/WageRate/Preview';
import CompanyResourcesWageRateBulkUpload from 'af-root/scenes/Company/Resources/WageRate/BulkUpload';
import CompanyResourcesFieldWorkClassificationCodeList from 'af-root/scenes/Company/Resources/FieldWorkClassificationCode/List';
import CompanyResourcesFieldWorkClassificationCodeCreate from 'af-root/scenes/Company/Resources/FieldWorkClassificationCode/Create';
import CompanyResourcesFieldWorkClassificationCodeEdit from 'af-root/scenes/Company/Resources/FieldWorkClassificationCode/Edit';
import CompanyResourcesFieldWorkClassificationCodePreview from 'af-root/scenes/Company/Resources/FieldWorkClassificationCode/Preview';
import CompanyResourcesEquipmentCostList from 'af-root/scenes/Company/Resources/EquipmentCost/List';
import CompanyResourcesEquipmentCostTree from 'af-root/scenes/Company/Resources/EquipmentCost/Tree';
import CompanyResourcesEquipmentCostCreate from 'af-root/scenes/Company/Resources/EquipmentCost/Create';
import CompanyResourcesEquipmentCostEdit from 'af-root/scenes/Company/Resources/EquipmentCost/Edit';
import CompanyResourcesEquipmentCostBulkUpload from 'af-root/scenes/Company/Resources/EquipmentCost/BulkUpload';
import CompanyResourcesEquipmentList from 'af-root/scenes/Company/Resources/Equipment/List';
import CompanyResourcesEquipmentCreate from 'af-root/scenes/Company/Resources/Equipment/Equipment/Create';
import CompanyResourcesEquipmentPreview from 'af-root/scenes/Company/Resources/Equipment/Equipment/Preview';
import CompanyResourcesEquipmentEdit from 'af-root/scenes/Company/Resources/Equipment/Equipment/Edit';
import CompanyResourcesItemCreate from 'af-root/scenes/Company/Resources/Equipment/Item/Create';
import CompanyResourcesItemEdit from 'af-root/scenes/Company/Resources/Equipment/Item/Edit';
import CompanyResourcesItemPreview from 'af-root/scenes/Company/Resources/Equipment/Item/Preview';
import CompanyResourcesToolCreate from 'af-root/scenes/Company/Resources/Equipment/Tool/Create';
import CompanyResourcesToolEdit from 'af-root/scenes/Company/Resources/Equipment/Tool/Edit';
import CompanyResourcesToolPreview from 'af-root/scenes/Company/Resources/Equipment/Tool/Preview';
import CompanyResourcesEquipmentStatusHistory from 'af-root/scenes/Company/Resources/Equipment/Equipment/StatusHistory';
import CompanyResourcesEmployeeStatusHistory from 'af-root/scenes/Company/Resources/Employee/StatusHistory';
import CompanyResourcesEquipmentBulkUpload from 'af-root/scenes/Company/Resources/Equipment/Equipment/BulkUpload';
import CompanyResourcesEquipmentStatus from 'af-root/scenes/Company/Resources/EquipmentStatus';
import CompanyResourcesItemCategory from 'af-root/scenes/Company/Resources/ItemCategory';
import CompanyResourcesVendor from 'af-root/scenes/Company/Resources/Vendor';
import CompanyResourcesEmployeeList from 'af-root/scenes/Company/Resources/Employee/List';
import CompanyResourcesEmployeeEdit from 'af-root/scenes/Company/Resources/Employee/Edit';
import CompanyResourcesEmployeePreview from 'af-root/scenes/Company/Resources/Employee/Preview';
import CompanyResourcesEmployeeStatus from 'af-root/scenes/Company/Resources/EmployeeStatus';
import CompanyResourcesLocationList from 'af-root/scenes/Company/Resources/Location/List';
import CompanyResourcesLocationCreate from 'af-root/scenes/Company/Resources/Location/Create';
import CompanyResourcesLocationPreview from 'af-root/scenes/Company/Resources/Location/Preview';
import CompanyResourcesLocationEdit from 'af-root/scenes/Company/Resources/Location/Edit';
import CompanyResourcesSaleList from 'af-root/scenes/Company/Resources/Sale/List';
import CompanyResourcesSaleCreate from 'af-root/scenes/Company/Resources/Sale/Create';
import CompanyResourcesSaleEdit from 'af-root/scenes/Company/Resources/Sale/Edit';
import CompanyResourcesSalePreview from 'af-root/scenes/Company/Resources/Sale/Preview';
import CompanyResourcesCrewTypeList from 'af-root/scenes/Company/Resources/CrewType/List';
import CompanyResourcesDivisionList from 'af-root/scenes/Company/Resources/Division/List';
import CompanyResourcesSkillList from 'af-root/scenes/Company/Resources/Skill/List';
import CompanyResourcesDocumentList from 'af-root/scenes/Company/Resources/Document';
import CompanyDeliverable from 'af-root/scenes/Company/Resources/Deliverables';
import CompanyJobStatusList from 'af-root/scenes/Company/Resources/JobStatus/List';
import CompanyResourcesJobStatusPreview from 'af-root/scenes/Company/Resources/JobStatus/Preview';
import CompanyResourcesJobStatusCreate from 'af-root/scenes/Company/Resources/JobStatus/Create';
import CompanyResourcesJobStatusEdit from 'af-root/scenes/Company/Resources/JobStatus/Edit';
import CompanyResourcesTemporaryEmployeeList from 'af-root/scenes/Company/Resources/TemporaryEmployee';
import CompanyResourcesTemporaryEmployeeCreateContainer from 'af-root/scenes/Company/Resources/TemporaryEmployee/Create';
import CompanyResourcesTemporaryEmployeeEditContainer from 'af-root/scenes/Company/Resources/TemporaryEmployee/Edit';
import CompanyResourcesTemporaryEmployeePreviewContainer from 'af-root/scenes/Company/Resources/TemporaryEmployee/Preview';

// ToolRepair

import CompanyToolRepairTable from 'af-root/scenes/Company/ToolRepair/Table';
import CompanyToolRepairCreateForm from 'af-root/scenes/Company/ToolRepair/Create';
import CompanyToolRepairEditForm from 'af-root/scenes/Company/ToolRepair/Edit';
import CompanyToolRepairPreview from 'af-root/scenes/Company/ToolRepair/Preview';

// Deliverable
import DeliverableDashboard from 'af-root/scenes/Company/Deliverables';
import DeliverableFormContainer from 'af-root/scenes/Company/Deliverables/Deliverable';
import DeliverableSubmissionEditFormContainer from 'af-root/scenes/Company/Deliverables/Submission';
import DeliverableAssignmentCreateFormContainer from 'af-root/scenes/Company/Deliverables/Assignment/Create';
import DeliverableAssignmentEditFormContainer from 'af-root/scenes/Company/Deliverables/Assignment/Edit';
import { NotificationSnackbarContextProvider } from './context/notificationSnackbarContext';
import FieldReports from './scenes/Company/FieldReports';

import LMSLogin from 'af-components/LMSLogin';

const SCROLLBARS_AUTO_HIDE_DURATION = 200;
const SCROLLBARS_AUTO_HIDE_TIMEOUT = 200;
const SCROLLBARS_THUMB_MIN_SIZE = 60;

const withAuth = (permissiosPage?: Metadata) => {
	return permissiosPage
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		? (component: React.ComponentType<any>) => withPermission(permissiosPage)(RequireAuth(component))
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		: (component: React.ComponentType<any>) => RequireAuth(component);
};

const CompanyEditJobWithAuth = withAuth(
	PagePermissions.COMPANY.JOBS
)(CompanyJobEditRouter);

const CompanyPreviewJobWithAuth = withAuth(
	PagePermissions.COMPANY.JOBS
)(CompanyJobPreviewRouter);

const CompanyWorkRequestFormWithAuth = withAuth(
	PagePermissions.COMPANY.WORK_REQUEST
)(CompanyWorkRequestFormRouter);

const CompanyWorkRequestPreviewWithAuth = withAuth(
	PagePermissions.COMPANY.WORK_REQUEST
)(CompanyWorkRequestPreviewRouter);

const CompanyCreateJobWithAuth = withAuth(
	PagePermissions.COMPANY.JOBS.CREATE
)(CompanyJobCreateRouter);

/**
 * GoogleAnalytics - has to use router v6 API for pageview to work.
 */
type GAProps = ConnectedProps<typeof gaconnector>;

function mapDispatchToGAProps() {
	return {
		initializeGoogleAnalytics: GoogleAnalyticsActions.initializeGoogleAnalytics,
	};
}

const gaconnector = connect(null, mapDispatchToGAProps());

const GoogleAnalyticsBase: React.FC<GAProps> = (props) => {

	const { initializeGoogleAnalytics } = props;

	const [initialized, setInitialized] = React.useState(false);
	const location = useLocation();

	React.useEffect(() => {
		const initGA = async () => {
			if (process.env.GOOGLE_ANALYTICS_TRACKING_ID) {
				await initializeGoogleAnalytics();
				setInitialized(true);
			}
		};
		if (!initialized) {
			initGA();
		}
	}, [initializeGoogleAnalytics, initialized]);

	React.useEffect(() => {
		if (initialized) {
			ReactGA.pageview(window.location.host + location.pathname + location.search);
		}
	}, [initialized, location.pathname, location.search]);

	return (<></>);
};

const GoogleAnalytics = gaconnector(GoogleAnalyticsBase);

/**
 * Listener that handles orgAlias change. As of router v6 hooks must be used instead of external history object for route listening.
 */

const AppOrgAlias: React.FC = () => {

	const location = useLocation();

	React.useEffect(() => {
		if (!location.state) {
			location.state.orgAlias = getCurrentOrgAlias();
		} else {
			location.state = { orgAlias: getCurrentOrgAlias() };
		}
	}, [location]);

	return (<></>);
};

/**
 * App
 */
type Props = ConnectedProps<typeof connector>;

const AppFoo: React.FC<Props> = (props) => {

	const {
		refreshCurrentUser,
		platformAdminOrganizationLogin,
		orgAlias,
		companyName,
		findOrganizationPublicDetails,
		fetchActiveOrganizations,
		isAuthenticated,
		isAppReady,
	} = props;

	const [hasMountedAppOnPublicPage, setHasMountedAppOnPublicPage] = React.useState(false);

	React.useEffect(() => {
		const handleAppInit = async () => {

			const orgAliasExists = !(!orgAlias || orgAlias === 'app');
			const currentSignIn = orgAliasExists ? getSignInForOrg(orgAlias, true) : null;

			if (currentSignIn) {
				if (currentSignIn.isPlatformAdmin && orgAlias !== 'admin') {
					await platformAdminOrganizationLogin(orgAlias!);
				} else {
					await refreshCurrentUser(orgAlias!, companyName!);
				}
			} else if (!orgAliasExists) {
				await fetchActiveOrganizations();
			} else {
				await findOrganizationPublicDetails(orgAlias);
			}
		};
		handleAppInit();
		if (isCurrentPathPublic()) {
			setHasMountedAppOnPublicPage(true);
		}
	}, [
		companyName,
		fetchActiveOrganizations,
		findOrganizationPublicDetails,
		orgAlias,
		platformAdminOrganizationLogin,
		refreshCurrentUser,
	]);

	const loadingComponent = React.useCallback(() => {
		return (
			<div className="app__loading">
				<LoadingIndicator color="orange" />
			</div>);
	}, []);

	const renderAppMain = React.useCallback(() => {
		return (
			<div className="app__with-floating-sidenav">
				<Scrollbars
					autoHide={true}
					autoHideDuration={SCROLLBARS_AUTO_HIDE_DURATION}
					autoHideTimeout={SCROLLBARS_AUTO_HIDE_TIMEOUT}
					className="app-scrollable"
					id={APP_SCROLLBARS_ID}
					thumbMinSize={SCROLLBARS_THUMB_MIN_SIZE}
					universal={true}
				>
					<NotificationSnackbarContextProvider>
						<Loading />
						<ToastContainer />
						<Switch>
							{/* Home */}
							<Route component={HomeRedirect} exact={true} path="/" />
							<Route component={HomeRedirect} exact={true} path={CLIENT.HOME()} />
							<Route component={LMSLogin} exact={true} path={CLIENT.LMS()} />
							{/* Company */}
							<CompatRoute
								component={withAuth(PagePermissions.COMPANY.CREATE)(
									CreateCompanyContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.CREATE()}
							/>
							{/* Contacts */}
							<Route
								component={withAuth(PagePermissions.COMPANY.CONTACTS)(CompanyContactListContainer)}
								exact={true}
								path={CLIENT.COMPANY.CONTACTS.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.CONTACTS.MANAGE)(CompanyContactCreateContainer)}
								exact={true}
								path={CLIENT.COMPANY.CONTACTS.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.CONTACTS.MANAGE)(CompanyContactBulkUploadContainer)}
								exact={true}
								path={CLIENT.COMPANY.CONTACTS.BULK_UPLOAD()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.CONTACTS.MANAGE)(CompanyContactEditContainer)}
								exact={true}
								path={CLIENT.COMPANY.CONTACTS.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.CONTACTS)(CompanyContactPreviewContainer)}
								exact={true}
								path={CLIENT.COMPANY.CONTACTS.PREVIEW()}
							/>
							{/* Compliance */}
							{!DisabledFeatures.Compliance && (
								<Route
									component={Compliance}
									exact={true}
									path={CLIENT.COMPANY.COMPLIANCE.ROOT()}
								/>
							)}
							{!DisabledFeatures.Compliance && (
								<Route
									component={Compliance}
									exact={true}
									path={CLIENT.COMPANY.COMPLIANCE.PAGE()}
								/>
							)}
							{/* Dashboard */}
							<Route
								component={withAuth(PagePermissions.COMPANY.DASHBOARD)(
									CompanyDashboard
								)}
								exact={true}
								path={CLIENT.COMPANY.DASHBOARD.ROOT()}
							/>
							{/* Analytics */}
							<Route
								component={withAuth(PagePermissions.COMPANY.ANALYTICS)(
									CompanyAnalyticsEquipmentUtilization
								)}
								exact={true}
								path={CLIENT.COMPANY.ANALYTICS.EQUIPMENT_UTILIZATION()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.ANALYTICS)(
									CompanyAnalyticsLaborUtilization
								)}
								exact={true}
								path={CLIENT.COMPANY.ANALYTICS.LABOR_UTILIZATION()}
							/>
							{/* Deliverable */}
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.DELIVERABLE.MANAGE)(DeliverableDashboard)}
								exact={true}
								path={CLIENT.COMPANY.DELIVERABLE.DASHBOARD()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.DELIVERABLE.MANAGE)(DeliverableFormContainer)}
								exact={true}
								path={CLIENT.COMPANY.DELIVERABLE.JOB.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.DELIVERABLE.MANAGE)(DeliverableSubmissionEditFormContainer)}
								exact={true}
								path={CLIENT.COMPANY.DELIVERABLE.SUBMISSION.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.DELIVERABLE.MANAGE)(DeliverableAssignmentEditFormContainer)}
								exact={true}
								path={CLIENT.COMPANY.DELIVERABLE.ASSIGNMENT.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.DELIVERABLE.MANAGE)(DeliverableAssignmentCreateFormContainer)}
								exact={true}
								path={CLIENT.COMPANY.DELIVERABLE.ASSIGNMENT.CREATE()}
							/>
							{/* Jobs */}
							<Route
								component={withAuth()(CompanyJobsDashboardContainer)}
								exact={true}
								path={CLIENT.COMPANY.JOBS.DASHBOARD()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.JOBS)(CompanyJobsTableContainer)}
								exact={true}
								path={CLIENT.COMPANY.JOBS.TABLE()}
							/>
							<CompatRoute
								component={CompanyCreateJobWithAuth}
								exact={true}
								path={CLIENT.COMPANY.JOBS.CREATE_PARENT()}
							/>
							<CompatRoute
								component={CompanyPreviewJobWithAuth}
								exact={true}
								path={CLIENT.COMPANY.JOBS.PREVIEW_PARENT()}
							/>
							<CompatRoute
								component={CompanyEditJobWithAuth}
								exact={true}
								path={CLIENT.COMPANY.JOBS.EDIT_PARENT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.JOBS.MANAGE_INVOICES)(CompanyJobInvoiceCreateOrEditContainer)}
								exact={true}
								path={CLIENT.COMPANY.JOBS.INVOICE_CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.JOBS.MANAGE_INVOICES)(CompanyJobInvoiceCreateOrEditContainer)}
								exact={true}
								path={CLIENT.COMPANY.JOBS.INVOICE_EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.JOBS.MANAGE_INVOICES)(CompanyJobInvoicedInvoiceContainer)}
								exact={true}
								path={CLIENT.COMPANY.JOBS.INVOICE_PREVIEW()}
							/>
							{/* Work Requests */}
							<Route
								component={withAuth(PagePermissions.COMPANY.WORK_REQUEST)(CompanyWorkRequestTable)}
								exact={true}
								path={CLIENT.COMPANY.WORK_REQUESTS.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.WORK_REQUEST)(CompanyWorkRequestTable)}
								exact={true}
								path={CLIENT.COMPANY.WORK_REQUESTS.TABLE()}
							/>
							<CompatRoute
								component={CompanyWorkRequestFormWithAuth}
								exact={true}
								path={CLIENT.COMPANY.WORK_REQUESTS.CREATE_PARENT()}
							/>
							<CompatRoute
								component={CompanyWorkRequestFormWithAuth}
								exact={true}
								path={CLIENT.COMPANY.WORK_REQUESTS.EDIT_PARENT()}
							/>
							<CompatRoute
								component={CompanyWorkRequestPreviewWithAuth}
								exact={true}
								path={CLIENT.COMPANY.WORK_REQUESTS.PREVIEW_PARENT()}
							/>
							{/* Work Orders */}
							<Route
								component={withAuth(PagePermissions.COMPANY.WORK_ORDERS.MANAGE)(
									CompanyOrderContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.WORK_ORDERS.ORDER()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.WORK_ORDERS)(
									CompanyOrderConfirmationContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.WORK_ORDERS.CONFIRMATION()}
							/>
							{/* Schedule Board */}
							<Route
								component={withAuth(
									PagePermissions.COMPANY.WORK_ORDERS.SCHEDULE_BOARD
								)(CompanyScheduleBoardDailyViewContainer)}
								exact={true}
								path={CLIENT.COMPANY.SCHEDULE_BOARD.ROOT()}
							/>
							<Route
								component={withAuth(
									PagePermissions.COMPANY.WORK_ORDERS.SCHEDULE_BOARD
								)(CompanyScheduleBoardDailyViewContainer)}
								exact={true}
								path={CLIENT.COMPANY.SCHEDULE_BOARD.DAILY_VIEW()}
							/>
							<Route
								component={withAuth(
									PagePermissions.COMPANY.WORK_ORDERS.SCHEDULE_BOARD
								)(CompanyScheduleBoardWeeklyViewContainer)}
								exact={true}
								path={CLIENT.COMPANY.SCHEDULE_BOARD.WEEKLY_VIEW()}
							/>
							<Route
								component={withAuth(
									PagePermissions.COMPANY.WORK_ORDERS.SCHEDULE_BOARD
								)(CompanyScheduleBoardDisplayViewRotationContainer)}
								exact={true}
								path={CLIENT.COMPANY.DISPLAY_VIEW.ROOT()}
							/>
							<Route
								component={withAuth(
									PagePermissions.COMPANY.EQUIPMENT.MECHANIC_VIEW
								)(CompanyScheduleBoardMechanicViewContainer)}
								exact={true}
								path={CLIENT.COMPANY.MECHANIC_VIEW.ROOT()}
							/>
							{/* Field Report */}
							<CompatRoute
								component={withAuth(
									PagePermissions.COMPANY.FIELD_REPORT
								)(FieldReports)}
								exact={true}
								path={CLIENT.COMPANY.FIELD_REPORT.PARENT()}
							/>
							{/* Accounting */}
							<Route
								component={withAuth(PagePermissions.COMPANY.ACCOUNTING.PER_DIEM)(
									CompanyAccountingPerDiemReportList
								)}
								exact={true}
								path={CLIENT.COMPANY.ACCOUNTING.PER_DIEM_REPORT.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.ACCOUNTING.PREVAILING_WAGE)(
									CompanyAccountingPrevailingWages
								)}
								exact={true}
								path={CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.ACCOUNTING.PREVAILING_WAGE)(
									CompanyAccountingPrevailingWagesExceptionReport
								)}
								exact={true}
								path={CLIENT.COMPANY.ACCOUNTING.PREVAILING_WAGE_EXCEPTION_REPORT.TABLE()}
							/>
							{/* <Route
								component={withAuth(PagePermissions.COMPANY.ACCOUNTING.JOB_PAYROLL)(
									CompanyAccountingJobPayrollExport
								)}
								exact={true}
								path={CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_EXPORT()}
							/> */}
							{/* <Route
								component={withAuth(PagePermissions.COMPANY.ACCOUNTING.JOB_PAYROLL)(
									CompanyAccountingJobPayrollExportBeta
								)}
								exact={true}
								path={CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_EXPORT_BETA()}
							/> */}
							{/* <Route
								component={withAuth(PagePermissions.COMPANY.ACCOUNTING.JOB_PAYROLL)(
									CompanyAccountingJobPayrollReport
								)}
								exact={true}
								path={CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_REPORT.TABLE()}
							/> */}
							<Route
								component={withAuth(PagePermissions.COMPANY.ACCOUNTING.JOB_PAYROLL)(
									CompanyAccountingJobPayrollReportBeta
								)}
								exact={true}
								path={CLIENT.COMPANY.ACCOUNTING.JOB_PAYROLL_REPORT_BETA.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.ACCOUNTING.WORK_SUMMARY)(
									CompanyAccountingJobWorkSummary
								)}
								exact={true}
								path={CLIENT.COMPANY.ACCOUNTING.JOB_WORK_SUMMARY.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.PURCHASE_ORDER)(
									CompanyAccountingPurchaseOrder
								)}
								exact={true}
								path={CLIENT.COMPANY.PURCHASE_ORDER.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.PURCHASE_ORDER)(
									CompanyAccountingPurchaseOrderPreview
								)}
								exact={true}
								path={CLIENT.COMPANY.PURCHASE_ORDER.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.PURCHASE_ORDER)(
									CompanyAccountingPurchaseOrderPreview
								)}
								exact={true}
								path={CLIENT.COMPANY.PURCHASE_ORDER.PREVIEW()}
							/>
							<CompatRoute
								component={withAuth(PagePermissions.COMPANY.TOOL_REPAIR)(
									CompanyToolRepairTable
								)}
								exact={true}
								path={CLIENT.COMPANY.TOOL_REPAIR.TABLE()}
							/>
							<CompatRoute
								component={withAuth(PagePermissions.COMPANY.TOOL_REPAIR)(
									CompanyToolRepairCreateForm
								)}
								exact={true}
								path={CLIENT.COMPANY.TOOL_REPAIR.CREATE()}
							/>
							<CompatRoute
								component={withAuth(PagePermissions.COMPANY.TOOL_REPAIR)(
									CompanyToolRepairEditForm
								)}
								exact={true}
								path={CLIENT.COMPANY.TOOL_REPAIR.EDIT()}
							/>
							<CompatRoute
								component={withAuth(PagePermissions.COMPANY.TOOL_REPAIR)(
									CompanyToolRepairPreview
								)}
								exact={true}
								path={CLIENT.COMPANY.TOOL_REPAIR.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.ACCOUNTING.MANAGE_INVOICES)(
									CompanyAccountingInvoicesTable
								)}
								exact={true}
								path={CLIENT.COMPANY.ACCOUNTING.INVOICES.TABLE()}
							/>
							{/* Resources */}
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.TEMP_LABOR)(
									CompanyResourcesAgencyList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.AGENCY.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.TEMP_LABOR)(
									CompanyResourcesAgencyCreate
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.AGENCY.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.TEMP_LABOR)(
									CompanyResourcesAgencyEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.AGENCY.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.TEMP_LABOR)(
									CompanyResourcesAgencyPreview
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.AGENCY.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.WAGE_RATE)(
									CompanyResourcesWageRateList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.WAGE_RATE.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.WAGE_RATE)(
									CompanyResourcesWageRateCreate
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.WAGE_RATE.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.WAGE_RATE)(
									CompanyResourcesWageRateEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.WAGE_RATE.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.WAGE_RATE)(
									CompanyResourcesWageRatePreview
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.WAGE_RATE.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.WAGE_RATE)(
									CompanyResourcesWageRateBulkUpload
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.WAGE_RATE.BULK_UPLOAD()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE)(
									CompanyResourcesFieldWorkClassificationCodeList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE)(
									CompanyResourcesFieldWorkClassificationCodeCreate
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE)(
									CompanyResourcesFieldWorkClassificationCodeEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE)(
									CompanyResourcesFieldWorkClassificationCodePreview
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.FIELD_WORK_CLASSIFICATION_CODE.PREVIEW()}
							/>
							{/* // The route below is not accesable via UI, only via direct link */}
							<Route
								component={withAuth(
									PagePermissions.COMPANY.RESOURCES.EQUIPMENT_COST
								)(CompanyResourcesEquipmentCostList)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.LIST()}
							/>
							<Route
								component={withAuth(
									PagePermissions.COMPANY.RESOURCES.EQUIPMENT_COST
								)(CompanyResourcesEquipmentCostTree)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.TREE()}
							/>
							<Route
								component={withAuth(
									PagePermissions.COMPANY.RESOURCES.EQUIPMENT_COST
								)(CompanyResourcesEquipmentCostCreate)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.CREATE()}
							/>
							<Route
								component={withAuth(
									PagePermissions.COMPANY.RESOURCES.EQUIPMENT_COST
								)(CompanyResourcesEquipmentCostEdit)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.EDIT()}
							/>
							<Route
								component={withAuth(
									PagePermissions.COMPANY.RESOURCES.EQUIPMENT_COST
								)(CompanyResourcesEquipmentCostBulkUpload)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EQUIPMENT_COST.BULK_UPLOAD()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.VIEW_SIDENAV)(
									CompanyResourcesEquipmentList
								)}
								exact={true}
								path={`${CLIENT.COMPANY.EQUIPMENT.LIST()}/:tab?`}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.MANAGE)(
									CompanyResourcesEquipmentPreview
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.MANAGE)(
									CompanyResourcesEquipmentCreate
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.MANAGE)(
									CompanyResourcesEquipmentEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.MANAGE)(
									CompanyResourcesEquipmentStatusHistory
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.STATUS_HISTORY()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.MANAGE)(
									CompanyResourcesEquipmentBulkUpload
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.BULK_UPLOAD()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.ACCESSORIES.MANAGE)(
									CompanyResourcesItemCreate
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.ITEM.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.ACCESSORIES.MANAGE)(
									CompanyResourcesItemEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.ITEM.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.ACCESSORIES.VIEW)(
									CompanyResourcesItemPreview
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.ITEM.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.SMALL_TOOLS.MANAGE)(
									CompanyResourcesToolCreate
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.TOOL.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.SMALL_TOOLS.MANAGE)(
									CompanyResourcesToolEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.TOOL.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.SMALL_TOOLS.VIEW)(
									CompanyResourcesToolPreview
								)}
								exact={true}
								path={CLIENT.COMPANY.EQUIPMENT.TOOL.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.EQUIPMENT_STATUS)(
									CompanyResourcesEquipmentStatus
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EQUIPMENT_STATUS()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.VENDORS.MANAGE)(
									CompanyResourcesVendor
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.VENDOR()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.EQUIPMENT.ACCESSORIES.VIEW)(
									CompanyResourcesItemCategory
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.ITEM_CATEGORY()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.EMPLOYEE)(
									CompanyResourcesEmployeeList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EMPLOYEE.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.TEMP_LABOR)(
									CompanyResourcesTemporaryEmployeeList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.EMPLOYEE)(
									CompanyResourcesTemporaryEmployeeCreateContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.TEMP_LABOR)(
									CompanyResourcesTemporaryEmployeeEditContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.EDIT()}
							/>
							<Route
								component={CompanyResourcesTemporaryEmployeePreviewContainer}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.TEMPORARY_EMPLOYEE.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.EMPLOYEE)(
									CompanyResourcesEmployeePreview
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EMPLOYEE.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.EMPLOYEE)(
									CompanyResourcesEmployeeStatusHistory
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EMPLOYEE.STATUS_HISTORY()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.EMPLOYEE)(
									CompanyResourcesEmployeeEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EMPLOYEE.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.EMPLOYEE_STATUS)(
									CompanyResourcesEmployeeStatus
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.EMPLOYEE_STATUS()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.SALE)(
									CompanyResourcesSaleList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.SALE.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.SALE)(
									CompanyResourcesSaleCreate
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.SALE.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.SALE)(
									CompanyResourcesSaleEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.SALE.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.SALE)(
									CompanyResourcesSalePreview
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.SALE.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.LOCATION)(
									CompanyResourcesLocationList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.LOCATION.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.LOCATION)(
									CompanyResourcesLocationCreate
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.LOCATION.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.LOCATION)(
									CompanyResourcesLocationPreview
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.LOCATION.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.LOCATION)(
									CompanyResourcesLocationEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.LOCATION.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.CREW_TYPE)(
									CompanyResourcesCrewTypeList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.CREW_TYPE.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.DIVISION)(
									CompanyResourcesDivisionList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.DIVISION.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.SKILL)(
									CompanyResourcesSkillList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.SKILL.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.DOCUMENT)(
									CompanyResourcesDocumentList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.DOCUMENT.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.DELIVERABLE.MANAGE)(
									CompanyDeliverable
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.DELIVERABLE.LIST()}
							/>
							<Route
								component={CompanyOrdersTable}
								exact={true}
								path={CLIENT.COMPANY.ORDER.TABLE()}
							/>
							<Route
								component={CompanyOrdersForm}
								exact={true}
								path={CLIENT.COMPANY.ORDER.CREATE()}
							/>
							<Route
								component={CompanyOrdersForm}
								exact={true}
								path={CLIENT.COMPANY.ORDER.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.JOB_STATUS)(
									CompanyJobStatusList
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.JOB_STATUS.LIST()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.JOB_STATUS)(
									CompanyResourcesJobStatusCreate
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.JOB_STATUS.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.JOB_STATUS)(
									CompanyResourcesJobStatusPreview
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.JOB_STATUS.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.RESOURCES.JOB_STATUS)(
									CompanyResourcesJobStatusEdit
								)}
								exact={true}
								path={CLIENT.COMPANY.RESOURCES.JOB_STATUS.EDIT()}
							/>
							{/* Settings */}
							<Route
								component={withAuth()(CompanySettingsAccountContainer)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.ACCOUNT()}
							/>
							<Route
								component={withAuth()(CompanySettingsCompanyContainer)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.COMPANY()}
							/>
							<Route
								component={withAuth()(CompanySettingsScheduleBoardContainer)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.SCHEDULE_BOARD()}
							/>
							<Route
								component={withAuth()(CompanySettingsWorkOrderContainer)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.WORK_ORDER()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.NOTIFICATIONS)(
									CompanySettingsNotificationContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.NOTIFICATION()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.MEMBERS)(
									CompanySettingsCompanyMembersListContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.MEMBERS.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.MEMBERS.MANAGE)(
									CompanySettingsCompanyMembersBulkUploadContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.MEMBERS.BULK_UPLOAD()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.MEMBERS.MANAGE)(
									CompanySettingsCompanyMembersInviteContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.MEMBERS.INVITE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.MEMBERS.MANAGE)(
									CompanySettingsCompanyMembersRolesAndPermissionsContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.MEMBERS.ROLES_PERMISSIONS()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.MEMBERS.MANAGE)(
									CompanySettingsCompanyMemberEditContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.MEMBERS.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.LOGS)(
									CompanyLogsListContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.LOGS.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.LOGS)(
									CompanyLogContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.LOGS.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.USER_GROUPS)(
									CompanyUserGroupsListContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.USER_GROUPS.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.USER_GROUPS)(
									CompanyUserGroupFormContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.USER_GROUPS.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.USER_GROUPS)(
									CompanyUserGroupFormContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.USER_GROUPS.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.REPORT_MANAGE)(
									CompanySettingsReportContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.REPORT.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.REPORT_MANAGE.REPORT_BLOCK)(
									CompanySettingsCreateReportBlockContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.REPORT.REPORT_BLOCK.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.REPORT_MANAGE.REPORT_BLOCK)(
									CompanySettingsEditReportBlockContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.REPORT.REPORT_BLOCK.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.REPORT_MANAGE.REPORT_TYPE)(
									CompanySettingsCreateCustomReportTypeContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.REPORT.REPORT_TYPE.CUSTOM.CREATE()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.REPORT_MANAGE.REPORT_TYPE)(
									CompanySettingsEditCustomReportTypeContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.REPORT.REPORT_TYPE.CUSTOM.EDIT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.EQUIPMENT)(
									CompanySettingsEquipmentContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.EQUIPMENT()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.SETTINGS.LABOR)(
									CompanySettingsLaborContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.SETTINGS.LABOR()}
							/>
							<Route
								component={KnowledgeCenter}
								exact={true}
								path={CLIENT.COMPANY.KNOWLEDGE_CENTER()}
							/>
							{/* <Route
							exact={true}
							path={CLIENT.COMPANY.SETTINGS.CONNECTED_DEVICES()}
							component={withAuth(PagePermissions.COMPANY.SETTINGS.ACCOUNT)(
								CompanyConnectedDevicesContainer
							)}
						/> */}
							{/* Communication */}
							<Route
								component={withAuth(PagePermissions.COMPANY.COMMUNICATION.MANAGE)(
									CompanyNotificationCenterContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.COMMUNICATION.NOTIFY_EMPLOYEES()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.COMMUNICATION.MANAGE)(
									CompanyUrlShortenerContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.COMMUNICATION.URL_SHORTENER()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.COMMUNICATION.VIEW_COMMUNICATION_HISTORY)(
									CompanyCommunicationHistoryContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.COMMUNICATION.COMMUNICATION_HISTORY()}
							/>
							<Route
								component={withAuth(PagePermissions.COMPANY.COMMUNICATION.VIEW_COMMUNICATION_HISTORY)(
									CompanyMessagePreviewContainer
								)}
								exact={true}
								path={CLIENT.COMPANY.COMMUNICATION.MESSAGE_PREVIEW()}
							/>
							{/* Platform */}
							<Route
								component={withAuth()(AllOrganizationsContainer)}
								exact={true}
								path={CLIENT.PLATFORM.ALL_ORGANIZATIONS()}
							/>
							<Route
								component={withAuth()(CreateOrganizationContainer)}
								exact={true}
								path={CLIENT.PLATFORM.CREATE()}
							/>
							<Route
								component={withAuth()(NotifyOrganizationEmployeesContainer)}
								exact={true}
								path={CLIENT.PLATFORM.NOTIFY_EMPLOYEES()}
							/>
							<Route
								component={withAuth()(SystemNotificationsContainer)}
								exact={true}
								path={CLIENT.PLATFORM.SYSTEM_NOTIFICATIONS()}
							/>
							<Route
								component={withAuth()(OrganizationUsersContainer)}
								exact={true}
								path={CLIENT.PLATFORM.USERS.LIST()}
							/>
							{/* Organization */}
							<Route
								component={withAuth(PagePermissions.ORGANIZATION)(OrganizationSettingsContainer)}
								exact={true}
								path={CLIENT.ORGANIZATION.SETTINGS.INFO()}
							/>
							<Route
								component={withAuth(PagePermissions.ORGANIZATION)(OrganizationLogs)}
								exact={true}
								path={CLIENT.ORGANIZATION.SETTINGS.LOGS.TABLE()}
							/>
							<Route
								component={withAuth(PagePermissions.ORGANIZATION)(OrganizationLogContainer)}
								exact={true}
								path={CLIENT.ORGANIZATION.SETTINGS.LOGS.PREVIEW()}
							/>
							<Route
								component={withAuth(PagePermissions.ORGANIZATION)(
									OrganizationMembersContainer
								)}
								exact={true}
								path={CLIENT.ORGANIZATION.MEMBERS()}
							/>
							<CompatRoute component={Error404Container} exact={true} path="*" />
						</Switch>
					</NotificationSnackbarContextProvider>
				</Scrollbars>
			</div>
		);
	}, []);

	const renderNavigation = React.useCallback(() => {

		if (hasMountedAppOnPublicPage) {
			return null;
		}

		return (
			<>
				{/* Extra Switches needed for migration to router v6 */}
				<Switch>
					<CompatRoute component={Header} exact={true} path={CLIENT.ORGANIZATION.ROOT()} />
				</Switch>
				{/* Side navs */}
				<Switch>
					{isAuthenticated && <Route component={withAuth()(SideNav.Company)} path={CLIENT.COMPANY.SIDENAV()} />}
					<Route component={withAuth()(SideNav.PlatformAdmin)} path={CLIENT.PLATFORM.SIDENAV()} />
					<Route component={withAuth()(SideNav.Organization)} path={CLIENT.ORGANIZATION.SIDENAV()} />
					<Route component={withAuth()(SideNav.Empty)} path={CLIENT.AUTH.FINALIZE()} />
				</Switch>
				{/* Floating Sidenavs */}
				<Switch>
					<Route
						component={withAuth()(FloatingSideNav.Settings)}
						path={CLIENT.COMPANY.SETTINGS.SIDENAV()}
					/>
					<Route
						component={withAuth()(FloatingSideNav.Accounting)}
						path={CLIENT.COMPANY.ACCOUNTING.SIDENAV()}
					/>
					<Route
						component={withAuth()(FloatingSideNav.Resources)}
						path={CLIENT.COMPANY.RESOURCES.SIDENAV()}
					/>
					<Route
						component={withAuth()(FloatingSideNav.Communication)}
						path={CLIENT.COMPANY.COMMUNICATION.SIDENAV()}
					/>
					<Route
						component={withAuth()(FloatingSideNav.OrganizationResources)}
						path={CLIENT.ORGANIZATION.RESOURCES.ROOT()}
					/>
					<Route
						component={withAuth()(FloatingSideNav.OrganizationSettings)}
						path={CLIENT.ORGANIZATION.SETTINGS.SIDENAV()}
					/>
					<Route
						component={withAuth()(FloatingSideNav.Orders)}
						path={CLIENT.COMPANY.WORK_ORDERS.FLOATING_SIDENAV()}
					/>
					<Route
						component={withAuth()(FloatingSideNav.Analytics)}
						path={CLIENT.COMPANY.ANALYTICS.ROOT()}
					/>
				</Switch>
			</>
		);
	}, [hasMountedAppOnPublicPage, isAuthenticated]);

	const showNavigation = !hasMountedAppOnPublicPage;

	const appClassName = `app env_${__ENVIRONMENT__}`;

	return (
		<div className={appClassName}>
			<GoogleAnalytics></GoogleAnalytics>
			<AppOrgAlias></AppOrgAlias>
			<div className="app__container" id="app-container">
				{!isAppReady
					? (
						<div className="app__main">
							<Switch>
								{/* Errors */}
								<CompatRoute component={Error403Container} exact={true} path={CLIENT.ERROR.ERR403()} />
								<CompatRoute component={Error404Container} exact={true} path={CLIENT.ERROR.ERR404()} />
								<Route component={Error500Container} exact={true} path={CLIENT.ERROR.ERR500()} />
								<Route
									component={Error500Container}
									exact={true}
									path={CLIENT.ERROR.ERR500_GENERAL}
								/>
								{/* Anything Else */}
								<Route component={loadingComponent} />
							</Switch>
						</div>
					)
					: (
						<>
							{showNavigation && renderNavigation()}
							<div className={location.pathname === CLIENT.AUTH.RESET_PASS() ? 'app__main app__main--header-small' : `app__main${showNavigation ? '' : ' app__main--without-navigation'}`}>
								{!orgAlias || orgAlias === 'app'
									? (
										<Switch>
											<Route component={ChooseOrganizationContainer} exact={true} path="/" />
											<Route
												component={ChooseOrganizationContainer}
												exact={true}
												path={CLIENT.APP.ORGANIZATIONS}
											/>
											{/* Static privacy policy and TOS */}
											<Route
												component={PrivacyPolicy}
												exact={true}
												path={CLIENT.APP.PRIVACY_POLICY}
											/>
											<Route
												component={TermsOfService}
												exact={true}
												path={CLIENT.APP.TERMS_OF_SERVICE}
											/>
											<Route
												component={ForgotOrganizationContainer}
												exact={true}
												path={CLIENT.APP.ORGANIZATION_FORGOTTEN}
											/>
											<Route
												component={ForgotOrganizationSuccessContainer}
												exact={true}
												path={CLIENT.APP.ORGANIZATION_FORGOTTEN_SUCCESS}
											/>
											<Route component={renderAppMain} />
										</Switch>
									)
									: (
										<Switch>
											<Route
												component={CompanySmsNotificationConfirmationContainer}
												exact={true}
												path={CLIENT.COMPANY.SCHEDULE_BOARD.SMS_NOTIFICATION_CONFIRMATION()}
											/>
											{/* Feedback */}
											<Route
												component={CompanyOrderConfirmationPublicContainer}
												exact={true}
												path={CLIENT.FEEDBACK.WORK_ORDER()}
											/>
											<Route
												component={CompanyOrderConfirmationPersonalUnassignedContainer}
												exact={true}
												path={CLIENT.FEEDBACK.WORK_ORDER_PERSONAL_UNASSIGNED()}
											/>
											<Route
												component={CompanyOrderConfirmationPersonalContainer}
												exact={true}
												path={CLIENT.FEEDBACK.WORK_ORDER_PERSONAL()}
											/>
											<CompatRoute
												component={CompanyFieldReportsPublicReportContainer}
												exact={true}
												path={CLIENT.COMPANY.FIELD_REPORT.PUBLIC_REPORT()}
											/>
											{/* Authentication */}
											<Route
												component={RestrictAuthenticated(LoginContainer)}
												exact={true}
												path={CLIENT.AUTH.LOGIN()}
											/>
											<Route
												component={RestrictAuthenticated(CodeLoginContainer)}
												exact={true}
												path={CLIENT.AUTH.LOGIN_CODE()}
											/>
											<Route
												component={RestrictAuthenticated(PlatformAdminLoginContainer)}
												exact={true}
												path={CLIENT.AUTH.PLATFORM_ADMIN_LOGIN()}
											/>
											<Route
												component={LogoutContainer}
												exact={true}
												path={CLIENT.AUTH.LOGOUT()}
											/>
											<Route
												component={withAuth()(PasswordOutdatedContainer)}
												exact={true}
												path={CLIENT.AUTH.PASS_OUTDATED()}
											/>
											<Route
												component={withAuth()(FinalizeUserContainer)}
												exact={true}
												path={CLIENT.AUTH.FINALIZE()}
											/>
											<Route
												component={RestrictAuthenticated(PasswordForgottenContainer)}
												exact={true}
												path={CLIENT.AUTH.PASS_FORGOTTEN()}
											/>
											<Route
												component={RestrictAuthenticated(PasswordForgottenSuccessContainer)}
												exact={true}
												path={CLIENT.AUTH.PASS_FORGOTTEN_SUCCESS()}
											/>
											<Route
												component={RestrictAuthenticated(ResetPasswordContainer)}
												exact={true}
												path={CLIENT.AUTH.RESET_PASS()}
											/>
											<Route
												component={RestrictAuthenticated(ResetPasswordSuccessContainer)}
												exact={true}
												path={CLIENT.AUTH.RESET_PASS_SUCCESS()}
											/>
											{/* Errors */}
											<CompatRoute
												component={Error403Container}
												exact={true}
												path={CLIENT.ERROR.ERR403()}
											/>
											<CompatRoute
												component={Error404Container}
												exact={true}
												path={CLIENT.ERROR.ERR404()}
											/>
											<CompatRoute
												component={Error500Container}
												exact={true}
												path={CLIENT.ERROR.ERR500()}
											/>
											<CompatRoute
												component={Error500Container}
												exact={true}
												path={CLIENT.ERROR.ERR500_GENERAL}
											/>
											<Route component={renderAppMain} />
										</Switch>
									)
								}
							</div>
						</>
					)
				}
			</div>
		</div>
	);
};

function mapStateToProps(state: RootState) {
	return {
		isAppReady: state.general.isAppReady,
		isAuthenticated: state.user.isAuthenticated,
		orgAlias: getCurrentOrgAlias(),
		companyName: getCompanyNameInPath(),
	};
}

function mapDispatchToProps() {
	return {
		initializeGoogleAnalytics: GoogleAnalyticsActions.initializeGoogleAnalytics,
		refreshCurrentUser: AuthenticationActions.refreshCurrentUser,
		platformAdminOrganizationLogin: AuthenticationActions.platformAdminOrganizationLogin,
		findOrganizationPublicDetails: OrganizationsActions.findOrganizationPublicDetails,
		fetchActiveOrganizations: OrganizationsActions.fetchActiveOrganizations,
	};
}

const connector = connect(mapStateToProps, mapDispatchToProps());

export default connector(AppFoo);
