import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import { VendorPackageTypeLabel } from '@acceligentllc/shared/enums/vendorPackageType';

import Label from 'af-components/LockedValue/Label';

import type ItemVM from 'ab-viewModels/item/edit.viewModel';

import styles from './styles.module.scss';

interface OwnProps {
	item: ItemVM;
}

type Props = OwnProps;

const VendorInfo: React.FC<Props> = (props: Props) => {
	const {
		item,
	} = props;

	return (
		<>
			<div className={styles.section}>
				<div className={styles.title}>Vendor Information</div>
				<Row>
					<Col md={8}>
						<Label className={styles.label} label={'Vendor'} />
						<div className={styles.value}>{item.vendor?.name ?? 'N/A'}</div>
					</Col>
					<Col md={8}>
						<Label className={styles.label} label={'Vendor Part Number'} />
						<div className={styles.value}>{item.vendor?.partNumber ?? 'N/A'}</div>
					</Col>
					<Col md={8}>
						<Label className={styles.label} label={'Vendor Price'} />
						<div className={styles.value}>${item.vendor?.price ?? 'N/A'}</div>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<Label className={styles.label} label={'Package Type'} />
						<div className={styles.value}>{item.vendor?.packageType ? VendorPackageTypeLabel[item.vendor.packageType] : 'N/A'}</div>
					</Col>
					<Col md={8}>
						<Label className={styles.label} label={'Package Quantity'} />
						<div className={styles.value}>{item.vendor?.packageQuantity ?? 'N/A'}</div>
					</Col>
					<Col md={8}>
						<Label className={styles.label} label={'Vendor URL'} />
						<div className={styles.value}>{item.vendor?.url ?? 'N/A'}</div>
					</Col>
				</Row>
				<Row>
					<Col md={24}>
						<Label className={styles.label} label={'Notes'} />
						<div className={styles.value}>{item.vendor?.note ?? '-'}</div>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default React.memo(VendorInfo);
