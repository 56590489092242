import * as React from 'react';
import { compose } from 'redux';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { CellContext, Row } from '@tanstack/react-table';

import OrderStatus from '@acceligentllc/shared/enums/orderStatus';

import OrderDeliveryMethod from '@acceligentllc/shared/enums/orderDeliveryMethod';
import TimeFormat from '@acceligentllc/shared/enums/timeFormat';
import TimePeriodRecurrence from '@acceligentllc/shared/enums/timePeriodRecurrence';

import * as TimeUtils from '@acceligentllc/shared/utils/time';

import ActionsCell from 'af-components/Table/Cells/ActionsCell';
import Breadcrumbs from 'af-components/Breadcrumbs';
import ConfirmationModal from 'af-components/ConfirmationModal';
import EmptyCell from 'af-components/Table/Cells/EmptyCell';
import type { TableRef } from 'af-components/Table';
import TableNew from 'af-components/Table';
import type { TableProps } from 'af-components/Table/types';
import TextCell from 'af-components/Table/Cells/TextCell';
import UpdatedByCell from 'af-components/Table/Cells/UpdatedByCell';
import Dropdown from 'af-components/Controls/Dropdown';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import DateFilter from 'af-components/DateFilter';
import DynamicTooltip from 'af-components/DynamicTooltip';

import CLIENT from 'af-routes/client';

import type { RootState } from 'af-reducers';

import * as OrderActions from 'af-actions/orders';

import type OrderTableVM from 'ab-viewModels/order/orderTable.viewModel';

import { isAllowed } from 'ab-utils/auth.util';
import * as SettingsUtils from 'af-utils/settings.util';

import BrowserStorageEnum from 'ab-enums/browserStorage.enum';
import TableNameEnum from 'ab-enums/tableName.enum';
import TableButtonType from 'ab-enums/tableButtonType.enum';
import PagePermissions from 'ab-enums/pagePermissions.enum';
import type { OrderCompletionStatusFilterOption } from 'ab-enums/orderTypeFilter.enum';
import { OrderCompletionStatusFilter, OrderCompletionStatusFilterText } from 'ab-enums/orderTypeFilter.enum';

import type { TableContent } from 'ab-common/dataStructures/tableContent';
import { TableQuery } from 'ab-common/dataStructures/tableQuery';

import * as SettingsKeys from 'af-constants/settingsKeys';

import styles from './styles.module.scss';
import TooltipMessage from './tooltipMessage';

interface SettingProps {
	startDate: Date;
	endDate: Date;
	period: TimePeriodRecurrence;
}

type Props = ConnectedProps<typeof connector> & SettingProps & CustomRouteComponentProps;

const normalizeDateToDate = (item: string) => {
	return TimeUtils.normalizeDateToDate(item, TimeFormat.FULL_DATE);

};

const DATE_OPTIONS = Object.values(TimePeriodRecurrence);
const COMPLETION_OPTIONS = [
	{
		id: OrderCompletionStatusFilter.ALL,
		name: OrderCompletionStatusFilterText.ALL,
	},
	{
		id: OrderCompletionStatusFilter.COMPLETED,
		name: OrderCompletionStatusFilterText.COMPLETED,
	},
	{
		id: OrderCompletionStatusFilter.NOT_COMPLETED,
		name: OrderCompletionStatusFilterText.NOT_COMPLETED,
	},
];

const PAGE_NUMBER_KEY = SettingsKeys.ORDER_PAGE_NUMBER();

const DELETE_ORDER_MODAL_BODY = (
	<>
		You are about to permanently delete this order.
		<br />
		Are you sure you want to proceed?
	</>
);

const mapDeliveryMethodToDisplay = (method: string) => {
	if (method === OrderDeliveryMethod.UNKNOWN) {
		return 'N/A';
	}
	return method;
};

const OrdersTable: React.FC<Props> = (props) => {
	const { findForTable, history, companyName, location: { state: { orgAlias } }, deleteOrder, canManage, period: passedPeriod } = props;

	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = React.useState(false);
	const [orderToDelete, setOrderToDelete] = React.useState<Nullable<OrderTableVM>>(null);
	const tableRef = React.useRef<TableRef<OrderTableVM>>(null);

	const [startDate, setStartDate] = React.useState(TimeUtils.positionDate(props.startDate, 'start', 'day'));
	const [endDate, setEndDate] = React.useState(TimeUtils.positionDate(props.endDate, 'end', 'day'));
	const [period, setPeriod] = React.useState(passedPeriod);
	const [completionStatus, setCompletionStatus] = React.useState<OrderCompletionStatusFilterOption>(COMPLETION_OPTIONS[COMPLETION_OPTIONS.length - 1]);

	const shouldFilterByDate = React.useMemo(() => {
		return period !== TimePeriodRecurrence.ALL;
	}, [period]);

	const fetchRows = React.useCallback(async (tableRequestModel: TableQuery): Promise<TableContent<OrderTableVM>> => {
		const { page, pageSize, filterByText, sortBy } = tableRequestModel;
		const result = await findForTable(
			new TableQuery({ page, pageSize, sortBy, filterByText }),
			completionStatus.id,
			shouldFilterByDate ? TimeUtils.formatDate(startDate, TimeFormat.DB_DATE_ONLY) : null,
			shouldFilterByDate ? TimeUtils.formatDate(endDate, TimeFormat.DB_DATE_ONLY) : null
		);
		return result;
	}, [completionStatus, findForTable, shouldFilterByDate, startDate, endDate]);

	const renderStatusCell = React.useCallback((cell: CellContext<OrderTableVM, string>) => {
		const status = cell.row.original.status;

		let textCell = <TextCell className={styles['order-table__new-status']} value="New" />;

		switch (status) {

			case OrderStatus.COMPLETED: {
				textCell = <TextCell className={styles['order-table__completed-status']} value="Completed" />;
				break;
			}
			case OrderStatus.IN_PROGRESS: {
				textCell = <TextCell className={styles['order-table__in-progress-status']} value="In Progress" />;
				break;
			}
			default: {
				// Do nothing
			}
		}

		const tooltipMessage = <TooltipMessage cell={cell} />;

		return (
			<DynamicTooltip
				tooltipContent={tooltipMessage}
			>
				{
					textCell
				}
			</DynamicTooltip>);
	}, []);

	const redirectToEditOrderPage = React.useCallback((row: Row<OrderTableVM>) => {
		history.push(CLIENT.COMPANY.ORDER.EDIT(orgAlias, companyName, `${row.original.id}`));
	}, [companyName, history, orgAlias]);

	const confirmDeleteOrder = React.useCallback(async () => {
		if (!orderToDelete) {
			return;
		}
		await deleteOrder(orderToDelete.id);
		tableRef.current?.refreshTable();
	}, [deleteOrder, orderToDelete]);

	const onDeleteOrderClick = React.useCallback((row: Row<OrderTableVM>) => {
		setShowDeleteConfirmationModal(true);
		setOrderToDelete(row.original);
	}, []);

	const closeDeleteOrderConfirmationModal = React.useCallback(() => {
		setShowDeleteConfirmationModal(false);
		setOrderToDelete(null);
	}, []);

	const openPrintPreview = React.useCallback((orderId: number, initiatePrint: boolean, fullPrint?: boolean) => {
		const url = new URL(CLIENT.COMPANY.ORDER.EDIT(orgAlias, companyName, `${orderId}`), location.origin);
		url.searchParams.append('forPrint', 'true');
		if (initiatePrint) {
			url.searchParams.append('initiatePrint', 'true');

			if (fullPrint) {
				url.searchParams.append('fullPrint', 'true');
			}
		}

		window.open(url.toString(), '_blank');
	}, [companyName, orgAlias]);

	const resolveActionsButton = React.useCallback((_cell: CellContext<OrderTableVM, unknown>) => {
		const options = [
			{ onClick: () => openPrintPreview(_cell.row.original.id, true, true), label: 'Print Full' },
			{ onClick: () => openPrintPreview(_cell.row.original.id, true), label: 'Print Condensed' },
			{ onClick: () => openPrintPreview(_cell.row.original.id, false), label: 'Preview' },
		];

		if (_cell.row.original.status !== OrderStatus.COMPLETED) {
			options.push({ onClick: () => redirectToEditOrderPage(_cell.row), label: 'Edit' });
		}

		if (canManage && _cell.row.original.status !== OrderStatus.COMPLETED) {
			options.push({
				onClick: async () => {
					await onDeleteOrderClick(_cell.row);
					tableRef.current?.refreshTable();
				}, label: 'Delete',
			});
		}

		return (
			<ActionsCell
				id="actions"
				isActionDropdown={true}
				labelKey="label"
				options={options}
				valueKey="label"
			/>
		);
	}, [canManage, redirectToEditOrderPage, openPrintPreview, onDeleteOrderClick]);

	const filterByDate = React.useCallback((_startDate: Date, _endDate: Date) => {
		tableRef.current?.resetPagination();
		setStartDate(TimeUtils.positionDate(_startDate, 'start', 'day'));
		setEndDate(TimeUtils.positionDate(_endDate, 'end', 'day'));
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.ORDER_START_DATE(),
			_startDate,
			TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.ORDER_END_DATE(),
			_endDate,
			TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
	}, []);

	const changePeriod = React.useCallback((_period: TimePeriodRecurrence, _selected: Date) => {
		let _startDate: Date = _selected;
		let _endDate: Date = _selected;

		switch (_period) {
			case TimePeriodRecurrence.MONTHLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'month'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'month'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.WEEKLY:
				_startDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'start', 'week'),
					'start',
					'day'
				);
				_endDate = TimeUtils.positionDate(
					TimeUtils.positionDate(_selected, 'end', 'week'),
					'end',
					'day'
				);
				break;
			case TimePeriodRecurrence.DAILY:
			case TimePeriodRecurrence.CUSTOM:
				_startDate = TimeUtils.positionDate(_selected, 'start', 'day');
				_endDate = TimeUtils.positionDate(_selected, 'end', 'day');
			default:
				break;
		}

		tableRef.current?.resetPagination();
		setStartDate(_startDate);
		setEndDate(_endDate);
		setPeriod(_period);
		SettingsUtils.setItem(SettingsKeys.ORDER_PERIOD(), _period, BrowserStorageEnum.LOCAL_STORAGE);
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.ORDER_START_DATE(),
			_startDate, TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
		SettingsUtils.setItemWithFormatter(
			SettingsKeys.ORDER_END_DATE(),
			_endDate,
			TimeUtils.formatDate,
			BrowserStorageEnum.LOCAL_STORAGE,
			TimeFormat.FULL_DATE_WITH_DAY_OF_WEEK
		);
	}, []);

	const onChange = React.useCallback((option: OrderCompletionStatusFilterOption) => {
		tableRef.current?.resetPagination();
		setCompletionStatus(option);
	}, []);

	const render = React.useCallback((option: OrderCompletionStatusFilterOption) => {
		return <>{option.name}</>;
	}, []);

	const renderAdditionalFilter = React.useCallback(() => {
		return (
			<div className={styles['table-filter']}>
				<div className={styles['completed-filter']}>
					<Dropdown<OrderCompletionStatusFilterOption>
						className={styles['completed-filter-button']}
						defaultValue={COMPLETION_OPTIONS[COMPLETION_OPTIONS.length - 1]}
						id="field-reports-orders-user-type-filter"
						labelKey="name"
						onValueChange={onChange}
						options={COMPLETION_OPTIONS}
						renderMenuItem={render}
						valueKey="id"
						withBorder={false}
					/>
					<RectangleButton
						action={undefined}
						isLeftOpen={true}
						isSquare={true}
						label={<span className="icon-filter" />}
					/>
				</div>
				<DateFilter
					changePeriod={changePeriod}
					endDate={endDate}
					onChange={filterByDate}
					options={DATE_OPTIONS}
					period={period}
					startDate={startDate}
				/>
			</div>
		);
	}, [changePeriod, endDate, filterByDate, onChange, period, render, startDate]);

	const columns: TableProps<OrderTableVM>['columns'] = React.useMemo(() => [
		{
			id: 'status',
			accessor: 'status',
			header: 'Status',
			cell: renderStatusCell,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'operator',
			accessor: 'operator',
			header: 'Operator',
			cell: (cell: CellContext<OrderTableVM, OrderTableVM['operator']>) => <TextCell value={cell.getValue()?.fullName ?? 'N/A'} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'deliveryMethod',
			accessor: 'deliveryMethod',
			header: 'Delivery Method',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={mapDeliveryMethodToDisplay(cell.getValue())} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'orderType',
			accessor: 'orderType',
			header: 'Order Type',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue().replaceAll('_', ' ')} />,
			enableSorting: false,
			enableHiding: true,
		},
		{
			id: 'dateSubmitted',
			accessor: 'dateSubmitted',
			header: 'Date Submitted',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue() ?? 'N/A'} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'dateNeeded',
			accessor: 'dateNeeded',
			header: 'Date Needed',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue()} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'location',
			accessor: 'locationNickname',
			header: 'Location',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={cell.getValue() ?? 'N/A'} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'unfulfilled',
			accessor: 'unfulfilled',
			header: 'Unfulfilled',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'unassigned',
			accessor: 'unassigned',
			header: 'Unassigned',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'awaitingPickup',
			accessor: 'awaitingPickup',
			header: 'Awaiting Pickup',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'awaitingDelivery',
			accessor: 'awaitingDelivery',
			header: 'Awaiting Delivery',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'onOrder',
			accessor: 'onOrder',
			header: 'On Order',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'returned',
			accessor: 'returned',
			header: 'Returned',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'fulfilled',
			accessor: 'fulfilled',
			header: 'Fulfilled',
			cell: (cell: CellContext<OrderTableVM, string>) => <TextCell value={`${parseFloat(cell.getValue() ?? '0')}`} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'createdAt',
			accessor: 'requestedAt',
			header: 'Requested',
			cell: (cell: CellContext<OrderTableVM, string>) =>
				<UpdatedByCell updatedAt={cell.row.original.requestedAt} updatedBy={cell.row.original.requestedBy} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'updatedAt',
			accessor: 'updatedAt',
			header: 'Updated',
			cell: (cell: CellContext<OrderTableVM, string>) =>
				<UpdatedByCell updatedAt={cell.row.original.updatedAt} updatedBy={cell.row.original.updatedBy} />,
			enableSorting: true,
			enableHiding: true,
		},
		{
			id: 'actions',
			isDisplayColumn: true,
			header: () => <EmptyCell isHeader />,
			cell: resolveActionsButton,
		},
	], [renderStatusCell, resolveActionsButton]);

	const redirectToCreateOrderPage = React.useCallback(async () => {
		history.push(CLIENT.COMPANY.ORDER.CREATE(orgAlias, companyName));
	}, [companyName, history, orgAlias]);

	const buttons = React.useMemo(() => [
		{ label: 'New Order', type: TableButtonType.PRIMARY, hasPermission: true, onClick: redirectToCreateOrderPage },
	], [redirectToCreateOrderPage]);

	React.useEffect(() => {
		// Temporary fix, can probably be deleted once https://acceligent.atlassian.net/browse/AP-9177 gets implemented
		return () => {
			if (completionStatus.id !== OrderCompletionStatusFilter.NOT_COMPLETED) {
				SettingsUtils.setItem(SettingsKeys.ORDER_PAGE_NUMBER(), `${0}`, BrowserStorageEnum.LOCAL_STORAGE);
			}
		};
	}, [completionStatus.id]);

	return (
		<>
			<Breadcrumbs items={[{ label: 'Orders' }]} />
			<TableNew
				additionalFilter={renderAdditionalFilter}
				buttons={buttons}
				columns={columns}
				defaultPageSize={100}
				fetch={fetchRows}
				hasSearchInput={true}
				onRowClick={redirectToEditOrderPage}
				pageNumberKey={PAGE_NUMBER_KEY}
				ref={tableRef}
				searchTextKey={SettingsKeys.ORDER_SEARCH_TEXT()}
				tableName={TableNameEnum.ORDER}
			/>
			{(showDeleteConfirmationModal && orderToDelete) &&
				<ConfirmationModal
					body={DELETE_ORDER_MODAL_BODY}
					closeModal={closeDeleteOrderConfirmationModal}
					confirmAction={confirmDeleteOrder}
					confirmText="Delete"
					modalStyle="danger"
					showModal={showDeleteConfirmationModal}
					title="Delete Order"
				/>
			}
		</>
	);
};

const mapStateToProps = (state: RootState) => {
	const { companyData, userData } = state.user;
	if (!companyData || !userData) {
		throw new Error('User not logged in');
	}
	const { isCompanyAdmin, permissions } = companyData;
	const { role } = userData;

	return {
		companyName: companyData.name,
		canManage: isAllowed(PagePermissions.COMPANY.ORDER.MANAGE, permissions, isCompanyAdmin, role),
	};
};

const connector = connect(mapStateToProps, mapDispatchToProps());

function mapDispatchToProps() {
	return {
		findForTable: OrderActions.findForTable,
		deleteOrder: OrderActions.deleteOrder,
	};
}

const enhance = compose(
	SettingsUtils.withSettings<SettingProps>(() => ([
		{
			key: SettingsKeys.ORDER_START_DATE(),
			mappedName: 'startDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.ORDER_END_DATE(),
			mappedName: 'endDate',
			normalize: normalizeDateToDate,
			defaultValue: new Date(),
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
		{
			key: SettingsKeys.ORDER_PERIOD(),
			mappedName: 'period',
			defaultValue: TimePeriodRecurrence.DAILY,
			source: BrowserStorageEnum.LOCAL_STORAGE,
		},
	])),
	connector
);

export default enhance(OrdersTable);
