import * as React from 'react';

import type { CellContext } from '@tanstack/react-table';

import type OrderTableVM from 'ab-viewModels/order/orderTable.viewModel';

import styles from './styles.module.scss';

interface OwnProps {
	cell: CellContext<OrderTableVM, string>;
}

type Props = OwnProps;

const TooltipMessage: React.FC<Props> = (props) => {

	const { cell } = props;

	const items = cell.row.original.orderItems;

	return items.length ? (
		<table className={styles['tooltip-table']}>
			<thead>
				<tr>
					<th></th>
					<th>Item Name</th>
					<th>Qty</th>
				</tr>
			</thead>
			<tbody>
				{items.map((item, index) => (
					<tr key={index}>
						<td>{index + 1}</td>
						<td>{item.name}</td>
						<td>{item.quantity}</td>
					</tr>
				))}
			</tbody>
		</table>
	) : (
		<div>No items</div>
	);
};

export default React.memo(TooltipMessage);
