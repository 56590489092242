import * as React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { Field } from 'redux-form';

import type VendorRM from 'ab-requestModels/vendor/vendor.requestModel';

import type VendorVM from 'ab-viewModels/vendor/vendor.viewModel';

import MultipleOptionsButton from 'af-components/MultipleOptionsButton';
import RectangleButton from 'af-components/MultipleOptionsButton/RectangleButton';
import Tooltip from 'af-components/Tooltip';

import Input from 'af-fields/Input';

import styles from './styles.module.scss';

interface Props extends InjectedFormProps<VendorRM> {
	onSubmit: (form: VendorRM) => void;
	close: () => void;
	vendors: VendorVM[];
	id: Nullable<number>;
	formValue?: string;
}

const VendorForm: React.FC<Props> = (props: Props) => {
	const {
		handleSubmit,
		onSubmit,
		submitting,
		close,
		vendors,
		id,
		formValue,
	} = props;

	const handleKeyDown = React.useCallback((e: React.KeyboardEvent) => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			e.preventDefault();
			if (!submitting) {
				handleSubmit(onSubmit)(e);
			}
		}
	}, [handleSubmit, onSubmit, submitting]);

	const checkUniqueName = React.useCallback((value: string) => {
		if (vendors.some((_vendor) => _vendor.name === (formValue ?? value) && _vendor.id !== id)) {
			return 'Vendor already exists';
		}
	}, [vendors, id, formValue]);

	return (
		<form className={styles.form}>
			<Field
				autoFocus={true}
				centered={true}
				component={Input}
				cursorControl={true}
				hideErrorText={true}
				isStandalone={true}
				name="name"
				onKeyDown={handleKeyDown}
				placeholder="Enter Vendor Name"
				type="text"
				validate={checkUniqueName}
			/>
			<MultipleOptionsButton isLeftFlat={true}>
				<RectangleButton
					action={handleSubmit(onSubmit)}
					isLeftOpen={true}
					isSquare={true}
					label={
						<Tooltip message="Submit" >
							<span className="icon-check" />
						</Tooltip>
					}
				/>
				<RectangleButton
					action={close}
					isSquare={true}
					label={
						<Tooltip message="Close" >
							<span className="icon-close" />
						</Tooltip>
					}
				/>
			</MultipleOptionsButton>
		</form>
	);
};

export default React.memo(VendorForm);
