import * as React from 'react';
import type { CustomRouteComponentProps } from 'react-router-dom';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Button } from '@acceligentllc/storybook';

import * as VendorActions from 'af-actions/vendor';

import type VendorRM from 'ab-requestModels/vendor/vendor.requestModel';

import type VendorVM from 'ab-viewModels/vendor/vendor.viewModel';

import Breadcrumbs from 'af-components/Breadcrumbs';
import Loading from 'af-components/Loading';

import VendorPreview from './VendorPreview';
import VendorEdit from './VendorEdit';
import VendorCreate from './VendorCreate';

import styles from './styles.module.scss';

type OwnProps = CustomRouteComponentProps;

type Props = OwnProps & ConnectedProps<typeof connector>;

const Vendor = (props: Props) => {
	const {
		findAll,
		create,
		update,
		remove,
	} = props;

	const [adding, setAdding] = React.useState(false);
	const [vendors, setVendors] = React.useState<VendorVM[]>([]);
	const [editingId, setEditingId] = React.useState<Nullable<number>>(null);
	const [loading, setLoading] = React.useState(false);

	const load = React.useCallback(async () => {
		const _vendors = await findAll();
		setVendors(_vendors);
		setLoading(false);
	}, [findAll]);

	React.useEffect(() => {
		setLoading(true);
	}, []);

	React.useEffect(() => {
		if (loading) {
			load();
		}
	}, [load, loading]);

	const createVendor = React.useCallback(async (form: VendorRM) => {
		await create(form);
		await load();
	}, [create, load]);

	const updateVendor = React.useCallback(async (form: VendorRM) => {
		await update(editingId!, form);
		setEditingId(null);
		await load();
	}, [update, load, editingId]);

	const deleteVendor = React.useCallback(async (id: number) => {
		await remove(id);
		await load();
	}, [remove, load]);

	const toggleAdding = React.useCallback(() => {
		setAdding(!adding);
	}, [adding]);

	const toggleEditing = React.useCallback((id: Nullable<number>) => {
		setEditingId(id);
	}, [setEditingId]);

	if (loading) {
		return <Loading />;
	}

	return (
		<div >
			<Breadcrumbs items={[{ label: 'Vendors' }]} />
			<div className={styles['vendor-list-container-wrapper']}>
				{!vendors.length
					? <div className={styles['vendor-list-container-empty']}>There are no vendors available.</div>
					: <div className={styles['vendor-list-container']}>
						<div className={styles['vendor-list']}>
							{vendors.map((_vendor) =>
								<React.Fragment key={_vendor.id}>
									{_vendor.id === editingId ? (
										<VendorEdit
											id={editingId}
											toggleEdit={toggleEditing}
											update={updateVendor}
											vendor={_vendor}
											vendors={vendors}
										/>
									) : (
										<VendorPreview
											edit={toggleEditing}
											remove={deleteVendor}
											vendor={_vendor}
										/>
									)}
								</React.Fragment>
							)}
						</div>
					</div>
				}
				<div className={styles['add-button-container']}>
					{adding ?
						<VendorCreate
							close={toggleAdding}
							create={createVendor}
							id={editingId}
							vendors={vendors}
						/> :
						<Button
							icon="plus"
							label="Add Vendor"
							onClick={toggleAdding}
							style="link"
						/>
					}
				</div>
			</div>
		</div>
	);
};

function mapDispatchToProps() {
	return {
		findAll: VendorActions.findList,
		create: VendorActions.create,
		update: VendorActions.update,
		remove: VendorActions.remove,
	};
}

const connector = connect(null, mapDispatchToProps());

export default connector(Vendor);
