import type { Dispatch, AnyAction } from 'redux';
import { SubmissionError } from 'redux-form';

import API from 'af-constants/routes/api';

import { http } from 'af-utils/http.util';
import type { ErrorOverride } from 'af-utils/actions.util';
import { errorHandler } from 'af-utils/actions.util';

import type VendorVM from 'ab-viewModels/vendor/vendor.viewModel';

import * as EditVendorAPI from 'ab-api/web/vendor/edit';
import * as CreateVendorAPI from 'ab-api/web/vendor/create';
import * as DeleteVendorAPI from 'ab-api/web/vendor/delete';

import type { W_Vendor_Create_VM } from 'ab-api/web/vendor/create/viewModel';

import type { GetRootState } from 'af-reducers';

export function create(form: CreateVendorAPI.W_Vendor_Create_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.post<W_Vendor_Create_VM>(CreateVendorAPI.URL(), form);
		};

		const errorOverride: ErrorOverride = {
			err400: () => {
				throw new SubmissionError({ _error: 'Vendor already exists.' });
			},
		};

		return await errorHandler(action, dispatch, redirectTo, errorOverride);
	};
}

export function update(id: number, form: EditVendorAPI.W_Vendor_Edit_RM) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.put(EditVendorAPI.URL(id), form);
		};

		const errorOverride: ErrorOverride = {
			err400: () => {
				throw new SubmissionError({ _error: 'Vendor already exists.' });
			},
		};
		return await errorHandler(action, dispatch, redirectTo, errorOverride);
	};
}

export function remove(id: number) {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			await http.delete(DeleteVendorAPI.URL(id));
		};
		return await errorHandler(action, dispatch, redirectTo);
	};
}

export function findList() {
	return async (dispatch: Dispatch<AnyAction>, getState: GetRootState, { redirectTo }) => {
		const action = async () => {
			return await http.get<VendorVM[]>(API.V1.RESOURCES.VENDOR.FIND_LIST);
		};

		return await errorHandler(action, dispatch, redirectTo);
	};
}
