import * as React from 'react';

import type VendorVM from 'ab-viewModels/vendor/vendor.viewModel';

import Pill from 'af-components/Pills/Pill';

interface OwnProps {
	vendor: VendorVM;
	edit: (id: number) => void;
	remove: (id: number) => void;
}

type Props = OwnProps;

const Preview: React.FC<Props> = (props: Props) => {
	const {
		vendor,
		edit,
		remove,
	} = props;

	const onClick = React.useCallback(() => {
		edit(vendor.id);
	}, [edit, vendor.id]);

	const onRemove = React.useCallback(() => {
		remove(vendor.id);
	}, [remove, vendor.id]);

	const modalBody = React.useCallback(() => (
		<>
			You are about to delete vendor <b>{vendor.name}</b>.
			Are you sure you want to continue?
		</>
	), [vendor.name]);

	return (
		<Pill
			item={vendor}
			labelKey="name"
			modalBody={modalBody}
			modalTitle="Delete Vendor"
			onClick={onClick}
			removeItem={onRemove}
		/>
	);
};

export default React.memo(Preview);
