import * as React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router';

import BlobStorageImageSizeContainer from '@acceligentllc/shared/enums/blobStorageImageSizeContainer';

import { DEFAULT_EQUIPMENT_IMAGE } from 'ab-common/constants/value';

import CLIENT from 'af-routes/client';

import ImageTag from 'af-components/Image';
import Label from 'af-components/LockedValue/Label';
import LabelWithColor from 'af-components/LabelWithColor';
import Tag from 'af-components/Controls/Tag';

import type ItemVM from 'ab-viewModels/item/edit.viewModel';

import styles from './styles.module.scss';

interface OwnProps {
	item: ItemVM;
	hasManageAccessoriesPermission: boolean;
	companyName: string;
}

type Props = OwnProps;

const Details: React.FC<Props> = (props: Props) => {
	const {
		item,
		hasManageAccessoriesPermission,
		companyName,
	} = props;

	const history = useHistory();
	const location = useLocation<{ orgAlias: string; }>();

	const { orgAlias } = location.state;

	const goToEdit = React.useCallback(() => {
		history.push(CLIENT.COMPANY.EQUIPMENT.ITEM.EDIT(item.id.toString(), orgAlias, companyName));
	}, [history, item, orgAlias, companyName]);

	return (
		<>
			<div className={styles.section}>
				<div className={styles['header-info']}>
					<div className={styles.header}>{item.modelNumber}</div>
					{
						hasManageAccessoriesPermission &&
						<Button
							id={'edit-button'}
							onClick={goToEdit}
							type="button"
							variant="info"
						>
							Edit
						</Button>
					}
				</div>
			</div>
			<div className={styles.section}>
				<div className={styles['item-info-container']}>
					<ImageTag
						fallbackSrc={DEFAULT_EQUIPMENT_IMAGE}
						minSize={BlobStorageImageSizeContainer.SIZE_200X200}
						src={item.imageUrl}
						tryOriginal={true}
						tryRoot={true}
					/>
					<div className={styles['item-info']}>
						<Row>
							<Col md={8}>
								<Label className={styles.label} label={'Name'} />
								<div className={styles.value}>{item.name}</div>
							</Col>
							<Col md={8}>
								<Label className={styles.label} label={'Manufacturer'} />
								<div className={styles.value}>{item.manufacturer}</div>
							</Col>
							<Col md={8}>
								<Label className={styles.label} label={'Model Number'} />
								<div className={styles.value}>{item.modelNumber}</div>
							</Col>
						</Row>
						<Row>
							<Col md={8}>
								<Label className={styles.label} label={'Crew Type'} />
								<div className={styles.value}>
									{
										!!item.crewType
											? <LabelWithColor color={item.crewType.color} text={item.crewType.name} />
											: 'N/A'
									}
								</div>
							</Col>
							<Col md={16}>
								<Label className={styles.label} label={'Description'} />
								<div className={styles.value}>{item.description ?? '-'}</div>
							</Col>
						</Row>
					</div>

				</div>
				<Label className={styles.label} label={'Category'} />
				<div className={styles.categories}>
					{item.itemCategories.map((ic) => (
						<Tag key={ic.itemCategoryId} size="small">
							<span>{ic.name}</span>
						</Tag>
					))}
				</div>

			</div>
		</>
	);
};

export default React.memo(Details);
